import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../components/Api";

import Layout from "../components/Layout";
import SingleArticle from "../components/SingleArticle";
import GlobalState from "../contexts/GlobalState";

const SingleArticlePage = () => {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalArticlesApiOptions, setArticlesApiOptions] = useState({});

    const [articlesData, setArticlesData] = useState();
    const [articlesSettings, setArticlesSettings] = useState();
    const { setIsOpen } = useContext(GlobalState);

    const { slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setArticlesApiOptions({
            url: "articles-settings",
            callback: (res) => {
                setArticlesSettings(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "articles/" + slug,
            callback: (res) => {
                setArticlesData(res.data);
                setIsOpen(false);
            },
            catch404: () => {
                navigate("/PageNotFound");
            },
        });
        // eslint-disable-next-line
    }, [slug]);

    useEffect(() => {
        window.triggerScroll();
    })

    return (
        <>

            <Api options={generalDataApiOptions} />
            <Api options={generalArticlesApiOptions} />


            {
                articlesData ?

                    <Layout activePage="library">
                        <Helmet>
                            <title>{articlesData.og_title ? articlesData.og_title : articlesData.title }</title>
                            <meta name="title" content={articlesData.og_title ? articlesData.og_title : articlesData.title } />
                            <meta name="description" content={articlesData.og_description ? articlesData.og_description : ""} />

                            <meta property="og:title" content={articlesData.og_title ? articlesData.og_title : articlesData.title } />
                            <meta property="og:description" content={articlesData.og_description ? articlesData.og_description : ""} />
                            <meta property="og:image" content={articlesData.og_image ? process.env.REACT_APP_BASE_URL + articlesData.og_image : articlesData.image} />

                            <meta property="twitter:title" content={articlesData.og_title ? articlesData.og_title : articlesData.title } />
                            <meta property="twitter:description" content={articlesData.og_description ? articlesData.og_description : ""} />
                            <meta property="twitter:image" content={articlesData.og_image ? process.env.REACT_APP_BASE_URL + articlesData.og_image : process.env.REACT_APP_BASE_URL + articlesData.image} />
                        </Helmet>
                        <section className="single-article-banner" animate="down">
                            <div className="article-banner-content position-relative d-flex align-items-center justify-content-center">
                                <div className="single-artile-overlay position-absolute"></div>
                                <div className="article-banner-image position-absolute h-100">
                                    <img src={process.env.REACT_APP_BASE_URL + articlesData.image} alt="article-banner" />
                                </div>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10">

                                            <div className="single-article-excerpt position-relative">
                                                <h1>{articlesData.excerpt}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="single-article-content" animate="up">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="article-date single">
                                            <p>
                                                {articlesData.date}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={"single-article-description" + (!articlesData.articles.length ? " add-padd" : "")} dangerouslySetInnerHTML={{
                                            __html: articlesData.description
                                        }}>

                                        </div>
                                    </div>

                                </div>
                                {
                                    articlesData.articles.length ?
                                        <div className="row related-articles">
                                            <div className="related-articles-title">
                                                <h1>{articlesSettings?.related_articles}</h1>
                                            </div>

                                            {
                                                articlesData.articles.map((article, index) => (
                                                    index < 3 ?
                                                        <SingleArticle read={articlesSettings?.read_more} image={process.env.REACT_APP_BASE_URL + article.image} title={article.title} date={article.date} excerpt={article.excerpt} key={article.id} slug={article.slug}>

                                                        </SingleArticle>
                                                        : null
                                                ))
                                            }
                                        </div>

                                        : null
                                }
                            </div>
                        </section>


                    </Layout>
                    :
                    <div className="custom-loader"></div>
            }
        </>

    )

}

export default SingleArticlePage;