import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import SingleArticle from "../components/SingleArticle";
import { detectTouch } from "detect-touch";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import GlobalState from "../contexts/GlobalState";

const Articles = () => {


    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [articlesDataApiOptions, setArticlesDataApiOptions] = useState({});
    const [searchDataApiOptions, setSearchDataApiOptions] = useState({});

    const [startDate, setStartDate] = useState();


    const [articleData, setArticleData] = useState();
    const [showClear, setShowClear] = useState(false);
    const [articles, setArticles] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [nbOfPages, setNbOfPages] = useState([]);
    const [lastPage, setLastPage] = useState();

    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(true);

    const [query, setQuery] = useState("");

    const [showArticles, setShowArticles] = useState(false);


    // useEffect(() => {
    //     if (startDate) {


    //     }
    // }, [startDate])

    const clearFilters = () => {
        setQuery("");
        doSearch("");
        setStartDate();
        setShowClear(false);
    }

    const handleSearch = (e) => {
        if (query !== "") {
            setShowClear(true);
        }
        e.preventDefault();

        doSearch(query);

    }

    const doSearch = (s) => {
        setSearchDataApiOptions({
            url: "/search1",
            method: "get",
            params: {
                queryString: s
            },
            callback: (res) => {
                setCurrentPage(1);
                setLastPage(res.data.last_page);

                setArticles(res.data.data);

                if (currentPage === res.data.last_page) {
                    setDisableNext(true);
                } else {
                    setDisableNext(false);
                }

                if (currentPage === 1) {
                    setDisablePrev(true);
                } else {
                    setDisablePrev(false);
                }
            },

        });
    }


    useEffect(() => {
        if (lastPage) {
            setNbOfPages([])
            for (let index = 1; index < lastPage + 1; index++) {
                setNbOfPages((nbOfPages) => [...nbOfPages, index]);
            }
        }
        // eslint-disable-next-line
    }, [lastPage])


    useEffect(() => {
        setGeneralDataApiOptions({
            url: "articles-settings",
            callback: (res) => {
                setArticleData(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowArticles(true);
    }, [articles]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setShowArticles(false);

        setArticlesDataApiOptions({
            url: "articles?page=" + currentPage,
            callback: (res) => {
                setArticles(res.data.data);

                setLastPage(res.data.last_page);
                if (currentPage === res.data.last_page) {
                    setDisableNext(true);
                } else {
                    setDisableNext(false);
                }

                if (currentPage === 1) {
                    setDisablePrev(true);
                } else {
                    setDisablePrev(false);
                }


                // if (selectedSectors.length || selectedYears.length) {
                //     setShowClear(true);
                // } else {
                //     setShowClear(false);
                // }

            },
        });
    }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (startDate) {

            let date = new Date(startDate);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? ("0" + month) : month;
            day = day < 10 ? ("0" + day) : day;
            let dateString = year + '-' + month + '-' + day;
            setShowArticles(false);

            setArticlesDataApiOptions({
                url: "articles?page=" + currentPage,
                params: {
                    date: dateString
                },
                callback: (res) => {
                    setArticles(res.data.data);

                    setLastPage(res.data.last_page);
                    if (currentPage === res.data.last_page) {
                        setDisableNext(true);
                    } else {
                        setDisableNext(false);
                    }

                    if (currentPage === 1) {
                        setDisablePrev(true);
                    } else {
                        setDisablePrev(false);
                    }

                    setShowClear(true)
                },
            });
        }
    }, [currentPage, startDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.triggerScroll();
    })

    const { generalDataGlobal } =
        useContext(GlobalState);

    return (
        <>
            <Api options={generalDataApiOptions} />
            <Api options={articlesDataApiOptions} />
            <Api options={searchDataApiOptions} />



            {
                articleData ?
                    <Layout activePage="library">
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[6].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[6].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[6].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[6].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[6].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[6].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[6].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[6].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[6].image_path} />
                        </Helmet>
                        <div className="articles-title" animate="">
                            <h1> {articleData.title} </h1>
                        </div>

                        <section className="articles-page-section" animate="right">
                            <div className="container">
                                <div className="row search-row">
                                    <div className="col-lg-4 col-md-6">
                                        <form className="search-form " id="newsletter-form">
                                            <div className="search-wrapper position-relative">
                                                <input
                                                    className="search-input col-12"
                                                    type="text"
                                                    name="query"
                                                    placeholder={
                                                        articleData.search_placeholder
                                                    }
                                                    value={query}
                                                    onChange={({ target }) => setQuery(target.value)}
                                                />
                                                <button
                                                    className={"search-btn position-absolute col-4 " + (detectTouch() ? " touch" : "")}
                                                    onClick={handleSearch}
                                                >
                                                    {articleData.search_label}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-2 col-md-3 position-relative filter-col sectors-col-filter">
                                        {/* <div className="sectoral-filter article-year d-flex justify-content-between align-items-center" >
                                            <div className="sectoral-filter-title">
                                                <p>{articleData.date_placeholder}</p>
                                            </div>
                                            <img
                                                src="/assets/images/arrow-down-sign-to-navigate.svg"
                                                alt="arrow down"
                                            />

                                        </div> */}
                                        <DatePicker placeholderText={articleData.date_placeholder} selected={startDate} onChange={(date) => setStartDate(date)} />

                                        {/* {
                                        showYears ?
                                            <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute" >
                                                {
                                                    generalDataGlobal[0]?.years?.length ?
                                                        generalDataGlobal[0]?.years?.map((year) => (
                                                            // eslint-disable-next-line
                                                            <a href={null} key={year.id} style={{ color: yearLabel.includes(year.year) ? "#e30613" : "" }} onClick={() => selectYear(year.id, year.year)} >

                                                                <p >
                                                                    {year.year}
                                                                </p>
                                                            </a>

                                                        ))
                                                        : null
                                                }

                                            </div>
                                            :
                                            null
                                    } */}
                                    </div>
                                    {
                                        showClear ?

                                            <div className="col-lg-2 ms-lg-4 d-flex align-items-center" onClick={clearFilters}>
                                                <p className="clear-all-label surveys">{articleData.clear_all_label}</p>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <div className={"row articles-row" + (showArticles ? ' show' : '')}>
                                    {
                                        articles?.length ?
                                            articles?.map(article => (
                                                <SingleArticle read={articleData.read_more} image={process.env.REACT_APP_BASE_URL + article.image} title={article.title} date={article.date} excerpt={article.excerpt} key={article.id} slug={article.slug}>

                                                </SingleArticle>
                                            ))
                                            : null
                                    }

                                </div>
                                {
                                    articles?.length ?
                                        <div className="container pagination-container">
                                            <div className="row ">
                                                <div className="col-12">
                                                    <div className="pagination-content d-flex justify-content-center">
                                                        <div className={"prev-arrow me-2" + (disablePrev ? " low-opacity" : "")}
                                                            onClick={!disablePrev ? () => { setCurrentPage(currentPage - 1); } : undefined}
                                                        >
                                                            {/* eslint-disable-next-line*/}
                                                            <a href={null}>

                                                                <img src="/assets/images/next-arrow.svg" alt="" />
                                                            </a>

                                                        </div>

                                                        <div className="pages d-flex">
                                                            {
                                                                nbOfPages?.map((page, index) => (
                                                                    <div key={index} className={"page-div d-flex justify-content-center align-items-center me-2 " + (index + 1 === currentPage ? " active-page" : "")}
                                                                        onClick={() => { setCurrentPage(index + 1); }}
                                                                    >
                                                                        {/* eslint-disable-next-line*/}
                                                                        <a href={null}>

                                                                            <p>{page}</p>
                                                                        </a>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                        <div className={"next-arrow " + (disableNext ? " low-opacity" : "")}
                                                            onClick={!disableNext ? () => { setCurrentPage(currentPage + 1); } : undefined}
                                                        >
                                                            {/* eslint-disable-next-line*/}
                                                            <a href={null}>
                                                                <img src="/assets/images/next-arrow.svg" alt="" />
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div> :
                                        <div className="no-results container">
                                            <div className="row" style={{ marginBottom: "90px" }}>
                                                <div className="col-12 d-flex justify-content-center no-results">
                                                    <h1>{articleData.no_results}</h1>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </section>

                    </Layout>
                    :
                    <div className="custom-loader">

                    </div>

            }
        </>
    )

}

export default Articles;