const SingleSector = (props) => {
  return (
    <div className={"single-sector position-relative" + (props.page === "home" ? " home" : "")}>
      <div className="border-top-sector"></div>
      <div className={"sector-icon position-absolute" + (props.page === "home" ? " home" : "")}>
        <img src={process.env.REACT_APP_BASE_URL + props.icon} alt="" />
      </div>
      <div className={"sector-title" + (props.page === "home" ? " home" : "")}>
        <h1>{props.title}</h1>
      </div>
      <div className={"sector-excerpt" + (props.page === "home" ? " home" : "")}>
        <p>{props.excerpt}</p>
      </div>
      {
        props.image ?
          <div className="sector-image ratio ratio-16x9">
            <img src={process.env.REACT_APP_BASE_URL + props.image} alt="" />
          </div>
          :
          null
      }
    </div>
  );
};
export default SingleSector;
