import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";

const Contact = () => {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [contactDataApiOptions, setContactDataApiOptions] = useState({});

    const [contactSettings, setContactSettings] = useState();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [errors, setErrors] = useState();
    const [sending, setSending] = useState(false);
    // const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "contact-settings",
            callback: (res) => {
                setContactSettings(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formSubmitHandler = (e) => {

        e.preventDefault();
        setSending(true);
        setContactDataApiOptions({
            url: "/contact",
            method: "post",
            data: {
                name: name,
                email: email,
                website: website,
                subject: subject,
                message: message,
            },
            callback: (res) => {
                setName("");
                setEmail("");
                setWebsite("");
                setSubject("");
                setMessage("");
                setErrors("");
                setSending(false);
                setSuccess(contactSettings.success_message)
                setTimeout(() => {
                    setSuccess("")
                }, 3000);
            },
            catch422: (errors) => {
                setErrors(errors);
                setSending(false);
            },
        });
    }; // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        if (errors) {

            console.log(errors['email']);
        }
    }, [errors])

    useEffect(() => {
        window.triggerScroll();
    })

    const { generalDataGlobal } = useContext(GlobalState);

    return (
        <>
            <Api options={generalDataApiOptions} />
            <Api options={contactDataApiOptions} />


            {
                contactSettings ?
                    <Layout>
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[8].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[8].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[8].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[8].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[8].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[8].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[8].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[8].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[8].image_path} />
                        </Helmet>
                        <section className="contact-banner " animate="left">
                            <div className="banner-content position-relative">
                                <div className="banner-image">
                                    <img className="banner-image-img" src={process.env.REACT_APP_BASE_URL + contactSettings.image} alt="contact-banner-img" />
                                </div>
                                <div className="container get-in-touch-container h-100">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-lg-5 offset-lg-7">
                                            <div className="get-in-touch">
                                                <div className="get-in-touch-title">
                                                    <h1>{contactSettings.banner_title}</h1>
                                                </div>
                                                <div className="location-contact d-flex">
                                                    <div className="location-icon">
                                                        <img src={process.env.REACT_APP_BASE_URL + contactSettings.location_icon} alt="location" />
                                                    </div>
                                                    <a href={contactSettings.location_url} target="_blank" rel="noreferrer" className="url-loc">
                                                        <div className="location-text">
                                                            <p>
                                                                {contactSettings.location}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>

                                                <div className="email-contact d-flex">
                                                    <div className="email-icon">
                                                        <img src={process.env.REACT_APP_BASE_URL + contactSettings.email_location} alt="email" />
                                                    </div>
                                                    <a href={"mailto:" + contactSettings.email} className="url-loc">

                                                        <div className="location-text">
                                                            <p>
                                                                {contactSettings.email}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="telephone-contact d-flex">
                                                    <div className="telephone-icon">
                                                        <img src={process.env.REACT_APP_BASE_URL + contactSettings.telephone_icon} alt="" />
                                                    </div>
                                                    <a href={"tel:" + contactSettings.number_to_call} className="url-loc">
                                                        <div className="location-text">
                                                            <p>
                                                                {contactSettings.telephone}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="contact-us position-relative" animate="right">


                            {/* map */}

                            <div className="map-container h-100">
                                <iframe title="map" className="map-contact" src={contactSettings.map_link}></iframe>
                                {/* <iframe title="map" className="map h-100 w-100" src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13232.727585094764!2d" + contactSettings.map_coordinates2 +  "!3d"+ contactSettings.map_coordinates1 +"!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f40bf48428e85%3A0x8f3b11ef163f6b06!2sHelloTree!5e0!3m2!1sen!2slb!4v1661262612049!5m2!1sen!2slb"} ></iframe> */}

                            </div>

                            <div className="container contact-container h-100">
                                <div className="row h-100">
                                    <div className="col-lg-4 contact-us-col d-flex align-items-center offset-lg-2">
                                        <div className="contact-us-content">
                                            <div className="contact-title">
                                                <h1>
                                                    {contactSettings.description_title}
                                                </h1>
                                            </div>
                                            <div className="contact-excerpt">
                                                <p>
                                                    {contactSettings.description_excerpt}
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg-6 h-100 d-flex align-items-end">
                                        <form className="contact-form">
                                            <div className="row h-100">
                                                <div className="col-md-6 padding-l">
                                                    <input
                                                        type="text"
                                                        className={"w-100 contact-input " + ((errors && errors['name']) ? "red-border" : "")}
                                                        placeholder={
                                                            contactSettings.name_placeholder
                                                        }
                                                        style={{ marginBottom: (errors && errors['name']) ? "5px" : "20px" }}
                                                        id="full-name"
                                                        value={name}
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                    />

                                                    {
                                                        (errors && errors['name']) ?
                                                            <div className="error-messages">
                                                                <p>{errors['name']}</p>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                                <div className="col-md-6 padding-l">
                                                    <input
                                                        type="email"
                                                        className={"w-100 contact-input " + ((errors && errors['email']) ? "red-border" : "")}
                                                        placeholder={
                                                            contactSettings.email_placeholder
                                                        }
                                                        style={{ marginBottom: (errors && errors['email']) ? "5px" : "20px" }}
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                        }}

                                                    />
                                                    {
                                                        (errors && errors['email']) ?
                                                            <div className="error-messages">
                                                                <p>{errors['email']}</p>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                                <div className="col-md-6 padding-l">
                                                    <input
                                                        type="text"
                                                        className={"w-100 contact-input " + ((errors && errors['subject']) ? "red-border" : "")}
                                                        placeholder={
                                                            contactSettings.subject_placeholder
                                                        }
                                                        style={{ marginBottom: (errors && errors['subject']) ? "5px" : "20px" }}
                                                        id="subject"
                                                        value={subject}
                                                        onChange={(e) => {
                                                            setSubject(e.target.value);
                                                        }}

                                                    />

                                                    {
                                                        (errors && errors['subject']) ?
                                                            <div className="error-messages">
                                                                <p>{errors['subject']}</p>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                                <div className="col-md-6 padding-l">
                                                    <input
                                                        type="text"
                                                        className={"w-100 contact-input " + ((errors && errors['website']) ? "red-border" : "")}
                                                        placeholder={
                                                            contactSettings.website_placeholder
                                                        }
                                                        style={{ marginBottom: (errors && errors['website']) ? "5px" : "20px" }}
                                                        id="website"
                                                        value={website}
                                                        onChange={(e) => {
                                                            setWebsite(e.target.value);
                                                        }}

                                                    />
                                                    {
                                                        (errors && errors['website']) ?
                                                            <div className="error-messages">
                                                                <p>{errors['website']}</p>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className={"w-100 contact-input message " + ((errors && errors['message']) ? "red-border" : "")}
                                                        placeholder={
                                                            contactSettings.message_placeholder
                                                        }
                                                        style={{ marginBottom: (errors && errors['message']) ? "5px" : "20px" }}
                                                        id="message"
                                                        value={message}
                                                        onChange={(e) => {
                                                            setMessage(e.target.value);
                                                        }}

                                                    />
                                                    {
                                                        (errors && errors['message']) ?
                                                            <div className="error-messages">
                                                                <p>{errors['message']}</p>
                                                            </div>
                                                            :
                                                            null

                                                    }
                                                </div>
                                            </div>
                                            {

                                                <div className={"success-message " + (success ? " show" : "")}>
                                                    <p>
                                                        {contactSettings.success_message}
                                                    </p>
                                                </div>

                                            }


                                            <div className="sending-container d-flex">
                                                <button className="send-btn" onClick={formSubmitHandler} disabled={sending}>
                                                    {
                                                        sending ?
                                                            "Sending..."
                                                            :
                                                            contactSettings.send
                                                    }
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                        </section>
                    </Layout>
                    :
                    <div className="custom-loader"></div>
            }

        </>
    )

}

export default Contact;