import { Link } from "react-router-dom";

const SingleArticle = (props) => {
    return (

        <div className={"col-lg-4 col-sm-6  d-flex flex-column single-article-col"}>
            <div className="article-content h-100 d-flex flex-column shadow">
                <Link to={"/articles/" + props.slug}>

                    <div className="article-image ratio ratio-16x9">
                        <img src={props.image} alt="" />
                    </div>
                </Link>
                <div className="d-flex flex-column h-100">

                    <div className="d-flex flex-column h-100">

                        <div className="title-date-container d-flex justify-content-between align-items-center flex-grow-1">

                            <div className="article-title col-8">
                                <Link to={"/articles/" + props.slug}>
                                    <h1>
                                        {props.title}
                                    </h1>
                                </Link>

                            </div>
                            <div className="article-date">
                                <p>
                                    {props.date}
                                </p>
                            </div>
                        </div>
                        <div className="article-excerpt flex-grow-1">
                            <p>
                                {props.excerpt}
                            </p>
                        </div>
                    </div>
                    <Link to={"/articles/" + props.slug}>
                        <div className="read-more">
                            <p>
                                {props.read}
                            </p>
                        </div>
                    </Link>

                </div>
            </div>
        </div>
    )
}

export default SingleArticle;