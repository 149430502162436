// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from './pages/About';
import Sectors from './pages/Sectors'
import Reports from "./pages/Reports";
import Surveys from "./pages/Surveys";
import Library from "./pages/Library";
import Gallery from "./pages/Gallery";
import Articles from "./pages/Articles";
import TrackerMethodology from "./pages/TrackerMethodology";
import Disclaimer from "./pages/Disclaimer";
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound'
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Api from "./components/Api"; 
import axios from "axios";
import GlobalState from "../src/contexts/GlobalState";
import SingleSectorPage from "./pages/SingleSectorPage";
import SingleArticlePage from "./pages/SingleArticlePage";

function App() {
  const [popup, setPopup] = useState(false);
  // const axiosBaseUrl = "http://127.0.0.1:8000/api";
  // const axiosBaseUrl = "https://lta-backend.hellotree.dev/api";
  const axiosBaseUrl = "https://wherediditgo.org/api";
  axios.defaults.baseURL = axiosBaseUrl;

  const [generalData, setGeneralData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "general",
      callback: (res) => {
        setGeneralData(res.data); 
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps




  useEffect(() => {
    if (!localStorage.getItem("lta-first-visit")) {
      setPopup(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />

      <GlobalState.Provider
        value={{
          generalDataGlobal: [generalData, setGeneralData],
          setPopup,
          popup,
          setIsOpen,
          isOpen
        }}
      >
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              
              <Route index element={<Home />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="about" element={<About />} />

              {/* removed the tracker per client request will add it back when client requests to add it*/}
              
              {/* <Route path="tracker" element={<Tracker />} /> */}
              <Route path="sectors" element={<Sectors />} />
              <Route
                  exact
                  path="sectors/:slug"
                  element={<SingleSectorPage />}
                />
              <Route path="reports" element={<Reports />} />
              <Route path="surveys" element={<Surveys />} />
              <Route path="library" element={<Library />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="articles" element={<Articles />} />
              <Route
                  exact
                  path="articles/:slug"
                  element={<SingleArticlePage />}
                />
              <Route path="tracker-methodology" element={<TrackerMethodology />} />
              <Route path="faq" element={<Faq />} />
              <Route path="disclaimer" element={<Disclaimer />} />
              <Route path="contact-us" element={<Contact />} />

              

            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </GlobalState.Provider>
    </>
  );
}

export default App;
