import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import Api from "./Api";
// import logo from '../images/earrel.svg';

const Layout = (props) => {
  const { generalDataGlobal, popup, setPopup, isOpen, setIsOpen } =
    useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState("");

  useEffect(() => {
    console.log(subscribed);
  }, [subscribed])


  const [active, setActive] = useState(0);
  const [popupHeight, setPopupHeight] = useState();

  const handleTransition = () => {
    setPopupHeight(
      document.getElementsByClassName("terms-content")[0].clientHeight
    );
    document.getElementsByClassName("terms-content")[0].style.maxHeight =
      popupHeight;
    setActive(1);
  };

  useEffect(() => {
    if (isOpen) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "";
    }
  }, [isOpen]);

  useEffect(() => {
    if (popup) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "";
    }
  }, [popup]);

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, []);

  const subscribe = (e) => {
    e.preventDefault();
    setGeneralDataApiOptions({
      url: "/newsletter",
      method: "post",
      data: {
        email: email,
      },
      callback: (res) => {
        setEmail("");
        setSubscribed(res.data);
        console.log(res.data);

        setTimeout(() => {
          setSubscribed("");
        }, 3000);
      },
      catch422: (errors) => {
        setError(errors);
        setTimeout(() => {
          setError("");
        }, 3000);
      },
    });

  }

  return (
    <Fragment>
      <Api options={generalDataApiOptions} />

      <div className="section-space"></div>
      {/* desktop navbar */}
      <div className={"front-nav position-fixed shadow-custom"}>
        <div className="container">
          <nav className="d-flex justify-content-between ">
            <div className="logo">
              <Link to="/">
                <img src="/assets/images/earrel-logo.svg" alt="" />
              </Link>
            </div>
            <div className="menu-items position-relative ">
              <ul className="menu-list d-none d-md-block">
                <Link
                  to="/about"
                  className={props.activePage === "about" ? "activePage" : ""}
                >
                  <li>{generalDataGlobal[0]?.menu_items["about"]}</li>
                </Link>
                {/* <Link to="/tracker" className={props.activePage === "tracker" ? "activePage" : ""}>
                  <li>{generalDataGlobal[0]?.menu_items["tracker"]}</li>
                </Link> */}

                <Link
                  to="/sectors"
                  className={props.activePage === "sectors" ? "activePage" : ""}
                >
                  <li className="has-children-sectors">
                    {generalDataGlobal[0]?.menu_items["sectors"]}
                    <div className="sectors-list-container position-absolute">
                      <div className="sectors-list  d-flex flex-column align-items-center justify-content-center dropdown-shadow">
                        {generalDataGlobal[0]?.sectors_home.map(
                          (sector, index) => (
                            <div key={index} className="sector d-flex">
                              <Link to={"/sectors/" + sector.slug}>
                                <p className={"single-sector-title "}>
                                  {sector.title}
                                </p>
                              </Link>
                              <Link to={"/sectors/" + sector.slug}>
                                <img
                                  className="arrow-right"
                                  src="/assets/images/arrow-right.svg"
                                  alt=""
                                />
                              </Link>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </li>
                </Link>

                <Link to="/reports" className={props.activePage === "reports" ? "activePage" : ""}>
                  <li>{generalDataGlobal[0]?.menu_items["reports"]}</li>
                </Link>
                <Link to="/library" className={props.activePage === "library" ? "activePage" : ""}>
                  <li className="has-children-library">
                    {generalDataGlobal[0]?.menu_items["library"]}
                    <div className="library-list-container position-absolute">
                      <div className="library-list  d-flex flex-column align-items-center justify-content-center dropdown-shadow">
                        {generalDataGlobal[0]?.library_home.map(
                          (library, index) => (
                            <div key={index} className="library d-flex">
                              <Link to={"/" + library.slug} >

                                <p className="single-sector-title">{library.title}</p>
                              </Link>
                              <Link to={"/" + library.slug} >

                                <img
                                  className="arrow-right"
                                  src="/assets/images/arrow-right.svg"
                                  alt=""
                                />
                              </Link>
                            </div>

                          )
                        )}
                      </div>
                    </div>
                  </li>
                </Link>
              </ul>

              <div
                className="d-block d-md-none menu-button"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <div className="d-flex align-items-center h-100">
                  <img
                    src={
                      "/assets/images/" +
                      (!isOpen ? "open-menu.svg" : "close-menu.svg")
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className={"popup-container" + (isOpen ? " show" : "")}>
        <div className="front-nav">
          <div className="container">
            <nav className="d-flex justify-content-between ">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/images/earrel-logo.svg" alt="" />
                </Link>
              </div>
              <div
                className="d-block d-lg-none menu-button"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <div className="d-flex align-items-center h-100">
                  <img
                    src={
                      "/assets/images/" +
                      (!isOpen ? "open-menu.svg" : "close-menu.svg")
                    }
                    alt=""
                  />
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div
          className={
            "mobile-menu position-relative d-flex flex-column align-items-center justify-content-center" +
            (isOpen ? " show" : "")
          }
        >
          <div className="menu-items-mobile-container position-relative">
            <div className="img-container-top position-absolute">
              <img src="assets/images/leaf.svg" alt="" />
            </div>
            <div className="img-container-bottom position-absolute">
              <img src="assets/images/leaf.svg" alt="" />
            </div>
            <div className="menu-items-mobile d-flex flex-column align-items-center">
              <Link
                to="/about"
                className={props.activePage === "about" ? "activePage" : ""}
              >
                <p
                  className={"mobile-menu-category"}
                  style={{ marginBottom: 30 }}
                >
                  {generalDataGlobal[0]?.menu_items["about"]}
                </p>
              </Link>
              <Link
                to="/library"
                className={props.activePage === "library" ? "activePage" : ""}
              >
                <p className="mobile-menu-category">
                  {generalDataGlobal[0]?.menu_items["library"]}
                </p>
              </Link>


              <div
                className="  d-flex flex-column align-items-center justify-content-center"
                style={{ marginBottom: 30 }}
              >
                {generalDataGlobal[0]?.library_home.map((library, index) => (
                  <Link key={index} to={"/" + library.slug}>
                    <p

                      className="single-sector-title mb-1 mobile-menu-subcategory"
                    >
                      {library.title}
                    </p>
                  </Link>

                ))}
              </div>
              <Link
                to="/sectors"
                className={props.activePage === "sectors" ? "activePage" : ""}
              >
                <p className="mobile-menu-category">
                  {generalDataGlobal[0]?.menu_items["sectors"]}
                </p>
              </Link>

              <div
                className="  d-flex flex-column align-items-center justify-content-center"
                style={{ marginBottom: 30 }}
              >
                {generalDataGlobal[0]?.sectors_home.map((sector, index) => (
                  <Link to={"/sectors/" + sector.slug}
                    key={index}>
                    <p
                      className="single-sector-title mb-1 mobile-menu-subcategory"
                    >
                      {sector.title}
                    </p>
                  </Link>
                ))}
              </div>

              <Link to="/reports" className={props.activePage === "about" ? "activePage" : ""}>
                <p
                  className="mobile-menu-category"
                  style={{ marginBottom: 30 }}
                >
                  {generalDataGlobal[0]?.menu_items["reports"]}
                </p>
              </Link>
              {/* <Link to="/tracker" className={props.activePage === "tracker" ? "activePage" : ""}>
                <p className="mobile-menu-category">
                  {generalDataGlobal[0]?.menu_items["tracker"]}
                </p>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="container-fluid m-0">
          <div className="row buttons-row">
            <div className="col-4 p-0">
              <Link to="/contact-us">
                <button className="w-100">
                  {generalDataGlobal[0]?.footer_settings.contact}
                </button>
              </Link>

            </div>
            <div className="col-4 middle-button">
              <Link to="/disclaimer">
                <button className="w-100">
                  {generalDataGlobal[0]?.footer_settings.Disclaimer}
                </button>
              </Link>
            </div>
            <div className="col-4 p-0">
              <Link to="/faq">
                <button className="w-100">
                  {generalDataGlobal[0]?.footer_settings.FAQ}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>


      {/* content */}
      {props.children}

      {/* footer */}

      <div className="footer-section position-relative">
        <div className="container footer-content-container">
          <div className="row under-border">
            <div className="col-md-4 col-12">
              <div className="images-container">
                <div className="row">
                  <div className="col-lg-6 trans">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        generalDataGlobal[0]?.footer_settings.logo1
                      }
                      alt=""
                      className="me-4"
                    />
                  </div>
                  <div className="col-lg-6 trans">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        generalDataGlobal[0]?.footer_settings.logo2
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="excerpt-container">
                <p>{generalDataGlobal[0]?.footer_settings.excerpt}</p>
              </div>
              <div className="social-media d-flex mb-lg-4">
                <div className="icons-container d-flex">
                  {generalDataGlobal[0]?.social_media.map((icon, index) => (
                    <div key={index} className="icon-container me-4">
                      <a
                        href={icon.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          src={process.env.REACT_APP_BASE_URL + icon.icon}
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-4 col-6 d-flex justify-content-center align-items-center turn-absolute">
              <div className="middle-logo-container">
                <img
                  src={
                    process.env.REACT_APP_BASE_URL +
                    generalDataGlobal[0]?.footer_settings.middle_logo
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-4 d-flex flex-column justify-content-center col-letter">
              <div className="letter-title">
                <p>{generalDataGlobal[0]?.footer_settings.newsletter_title}</p>
              </div>

              <form onSubmit={subscribe} className="newsletter-form" id="newsletter-form">
                <div className="letter-wrapper position-relative">
                  <input
                    className="col-12"
                    type="email"
                    name="email"
                    placeholder={
                      generalDataGlobal[0]?.footer_settings.letter_palceholder
                    }
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <button
                    type="submit"
                    className="letter-button col-5 position-absolute"
                  >
                    {" "}
                    {generalDataGlobal[0]?.footer_settings.subscribe}
                  </button>
                </div>
                {
                  subscribed !== "" ?
                    <p className="subscribed">{subscribed}</p>
                    :
                    error ?
                      <p className="subscribed">{error.email}</p>
                      :
                      null
                }
              </form>
            </div>
          </div>
          <hr className="footer-hr" />

          <div className="row">
            <div className="col-md-5 contact-footer d-flex mt-4">
              <Link to="/contact-us">
                <p className="me-4 position-relative">
                  {generalDataGlobal[0]?.footer_settings.contact}
                </p>
              </Link>

              <Link to="/disclaimer">
                <p className="me-4 position-relative">
                  {generalDataGlobal[0]?.footer_settings.Disclaimer}
                </p>
              </Link>

              <Link to="/faq">
                <p className="position-relative">
                  {generalDataGlobal[0]?.footer_settings.FAQ}
                </p>
              </Link>
            </div>
            <div className="col-lg-4 offset-lg-3">
              <div className="designed-by">
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-6 designed-col">
                    <div
                      className="designed-by-div"
                      dangerouslySetInnerHTML={{
                        __html:
                          generalDataGlobal[0]?.footer_settings.designed_by,
                      }}
                    ></div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-6 designed-col">
                    <p>
                      {generalDataGlobal[0]?.footer_settings.rights.replace(
                        "%%year%%",
                        new Date().getFullYear()
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popup ? (
        <div className="terms-popup">
          <div
            className="terms-content"
            style={{ maxHeight: popupHeight ? popupHeight : "" }}
          >
            <div
              className="container privacy-title-container"
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              <div className="row terms-row">
                <div
                  className={"col-6 terms-title-container first"}
                  onClick={() => {
                    setActive(0);
                  }}
                >
                  <div>
                    <h1
                      className={
                        "terms-title privacy" + (active === 0 ? " active" : "")
                      }
                    >
                      {generalDataGlobal[0]?.popup.privacy_title}
                    </h1>
                  </div>
                </div>
                <div
                  className="col-6 terms-title-container second"
                  // onClick={() => setActive(1)}
                  onClick={() => handleTransition()}
                >
                  <h1
                    className={
                      "terms-title terms" +
                      (active === 1 ? " active-terms" : "")
                    }
                  >
                    {generalDataGlobal[0]?.popup.terms_title}
                  </h1>
                </div>
              </div>
              <div className="border-terms conditions first">
                <div
                  className="one"
                  style={{ left: active === 0 ? "0" : "50%" }}
                ></div>
              </div>
            </div>

            {active === 0 ? (
              <>
                <div className="container privacy-title">
                  <div className="row ">
                    {/* <div className="col-12 d-flex justify-content-center privacy-subtitle">
                      <h1>
                        {generalDataGlobal[0]?.popup.privacy_content_title}
                      </h1>
                    </div> */}
                    <div className="col-12">
                      <div
                        className="privacy-content test"
                        dangerouslySetInnerHTML={{
                          __html:
                            generalDataGlobal[0]?.popup.privacy_description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container scrollable-container">
                  {/* {generalDataGlobal[0]?.terms.map((term, index) => (
                    <>
                      {index > 0 ? (
                        <div className="border-terms conditions"></div>
                      ) : null}
                      <div key={index} className="row conditions-row">
                        <div className="col-12">
                          <div className="privacy-title conditions">
                            <h1 className="conditions-h1">{term.title}</h1>
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className={"privacy-content conditions" + (index === generalDataGlobal[0].terms.length - 1 ? " last" : "")}
                            dangerouslySetInnerHTML={{
                              __html: term.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </>
                  ))} */}

                  <div
                    className="row conditions-row"
                    dangerouslySetInnerHTML={{
                      __html: generalDataGlobal[0]?.popup.terms,
                    }}
                  ></div>
                </div>
              </>
            )}
            <div
              className={
                "buttons-container" +
                (active === 0
                  ? " d-flex justify-content-between align-items-center"
                  : "")
              }
            >
              <div
                className="accept-btn-container"
                style={{ marginTop: active === 1 ? "20px" : "" }}
                onClick={() => (
                  // eslint-disable-next-line
                  setPopup(false),
                  localStorage.setItem("lta-first-visit", false)
                )}
              >
                <button className="accept-button">
                  {" "}
                  {generalDataGlobal[0]?.popup.accept}{" "}
                </button>
              </div>
              <div
                className={
                  "next-btn-container" + (active === 1 ? " d-none" : "")
                }
                onClick={() => handleTransition()}
              >
                <p className="next-button">
                  {generalDataGlobal[0]?.popup.next}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
export default Layout;
