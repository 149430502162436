import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";

const Disclaimer = (props) => {
  const { generalDataGlobal, } = useContext(GlobalState);

  const [active, setActive] = useState(1);

  useEffect(() => {
    window.triggerScroll();
  })

  return (
    <Layout>
      <Helmet>
        <title>{generalDataGlobal[0]?.seo[9].title}</title>
        <meta name="title" content={generalDataGlobal[0]?.seo[9].title} />
        <meta name="description" content={generalDataGlobal[0]?.seo[9].description} />

        <meta property="og:title" content={generalDataGlobal[0]?.seo[9].title} />
        <meta property="og:description" content={generalDataGlobal[0]?.seo[9].description} />
        <meta property="og:image" content={generalDataGlobal[0]?.seo[9].image_path} />

        <meta property="twitter:title" content={generalDataGlobal[0]?.seo[9].title} />
        <meta property="twitter:description" content={generalDataGlobal[0]?.seo[9].description} />
        <meta property="twitter:image" content={generalDataGlobal[0]?.seo[9].image_path} />
      </Helmet>
      <div className="terms-privacy-container" animate="right">
        <div className="container">
          <div className="row justify-content-center titles-row">
            <div
              className={
                "col-sm-5 col-md-5 terms-col" +
                (active === 1 ? " active-title" : "")
              }
              onClick={() => setActive(1)}
            >
              <div className="terms-and-conditions-title">
                <h1>{generalDataGlobal[0]?.popup.terms_title}</h1>
              </div>
            </div>
            <div
              className={
                "col-sm-5 col-md-5 privacy-col" +
                (active === 2 ? " active-title" : "")
              }
              onClick={() => setActive(2)}
            >
              <div className="privacy-policy-title">
                <h1>{generalDataGlobal[0]?.popup.privacy_title}</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              {active === 1 ? (
                <div
                  className="terms-description-section d-flex flex-column justify-content-center align-items-center"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0]?.popup.terms,
                  }}
                ></div>
              ) : (
                <div
                  className="terms-description-section d-flex flex-column justify-content-center align-items-center"
                  dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0]?.popup.privacy_description,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Disclaimer;
