export default function SubsectorGrid({ refs, setSubsectorContentHeight, sub, initialIndex, onSubsectorClick, selectedSubsectorId }) {
	return (
		<div className="col-xxl-2 col-lg-3 sub d-flex flex-column align-items-center position-relative" key={sub.id} onClick={() => onSubsectorClick(refs, setSubsectorContentHeight, sub.id, initialIndex)}>
			<div className="clicked-overlay position-absolute d-none d-lg-block"></div>
			{
				sub.id === selectedSubsectorId && (
					<div className="triangle-down position-absolute"></div>
				)
			}

			<div className={"sub-sector-icon-title d-flex flex-column align-items-center" + (sub.id === selectedSubsectorId ? " active-sub" : "")}>
				<div className="sub-sector-icon">
					<img src={process.env.REACT_APP_BASE_URL + sub.icon} alt="sub sector icon" />
				</div>
				<div className={"sub-sector-title" + (sub.id === selectedSubsectorId ? " active-sub" : "")}>
					<h1>{sub.title}</h1>
				</div>
			</div>
		</div>
	);
}