import { useEffect, useState } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";


const Reports = () => {
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [reportsDataApiOptions, setReportsDataApiOptions] = useState({});
  const [sectorsDataApiOptions, setSectorsDataApiOptions] = useState({});
  const [subsectorsDataApiOptions, setSubsectorsDataApiOptions] = useState({});

  const { setIsOpen } = useContext(GlobalState);
  const [reportsSettings, setReportsSettings] = useState();
  const [reports, setReports] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [nbOfPages, setNbOfPages] = useState([]);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [sectors, setSectors] = useState();
  const [subsectors, setSubsectors] = useState();
  const [donors, setDonors] = useState();
  const [years, setYears] = useState();
  const [showSectors, setShowSectors] = useState(false);
  const [showSubsectors, setShowSubsectors] = useState(false);
  const [showDonors, setShowDonors] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedSubsectors, setSelectedSubsectors] = useState([]);
  const [selectedDonors, setSelectedDonors] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);

  const [sectorLabel, setSectorLabel] = useState([]);
  const [subsectorLabel, setSubsectorLabel] = useState([]);
  const [donorLabel, setDonorLabel] = useState([]);
  const [yearLabel, setYearLabel] = useState([]);

  const [showClear, setShowClear] = useState(false);

  const [showSurveys, setShowSurveys] = useState(false);


  useEffect(() => {
    window.triggerScroll();
  })

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  useEffect(() => {
    setShowSurveys(true);
  }, [reports]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setShowSurveys(false);

    setReportsDataApiOptions({
      url: "reports?page=" + currentPage,
      params: {
        sectors: selectedSectors,
        subsectors: selectedSubsectors,
        donors: selectedDonors,
        years: selectedYears
      },
      callback: (res) => {
        setReports(res.data.reports.data);
        setLastPage(res.data.reports.last_page);
        setDonors(res.data.donors);
        setYears(res.data.years);
        if (currentPage === res.data.reports.last_page) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }

        if (currentPage === 1) {
          setDisablePrev(true);
        } else {
          setDisablePrev(false);
        }

        if (selectedSectors.length || selectedYears.length || selectedSubsectors.length || selectedDonors.length) {
          setShowClear(true);
        } else {
          setShowClear(false);
        }

      },
    });
  }, [currentPage, selectedSectors, selectedSubsectors, selectedDonors, selectedYears]); // eslint-disable-line react-hooks/exhaustive-deps


  //removing the filters with the x button

  const removeSector = (title) => {
    let tempSectors = [...sectorLabel];
    let newSelectedSectors = [...selectedSectors];
    let sectorsIndex = newSelectedSectors.indexOf(sectors[getIndex(title, sectors, "title")].id);
    let labelIndex = tempSectors.indexOf(title);
    tempSectors.splice(labelIndex, 1);
    newSelectedSectors.splice(sectorsIndex, 1);
    setSelectedSectors(newSelectedSectors);
    setSectorLabel(tempSectors);
  }

  const removeSubsector = (title) => {
    let tempSubsectors = [...subsectorLabel];
    let newSelectedSubsectors = [...selectedSubsectors];
    let sectorsIndex = newSelectedSubsectors.indexOf(subsectors[getIndex(title, subsectors, "title")].id);
    let labelIndex = tempSubsectors.indexOf(title);
    tempSubsectors.splice(labelIndex, 1);
    newSelectedSubsectors.splice(sectorsIndex, 1);
    setSelectedSubsectors(newSelectedSubsectors);
    setSubsectorLabel(tempSubsectors);
  }

  const removeDonor = (title) => {
    let tempDonor = [...donorLabel];
    let newSelectedDonor = [...selectedDonors];
    let sectorsIndex = newSelectedDonor.indexOf(donors[getIndex(title, donors, "title")].id);
    let labelIndex = tempDonor.indexOf(title);
    tempDonor.splice(labelIndex, 1);
    newSelectedDonor.splice(sectorsIndex, 1);
    setSelectedDonors(newSelectedDonor);
    setDonorLabel(tempDonor);
  }

  const removeYear = (title) => {
    let tempYear = [...yearLabel];
    let newSelectedYear = [...selectedYears];
    let sectorsIndex = newSelectedYear.indexOf(years[getIndex(title, years, "year")].id);
    let labelIndex = tempYear.indexOf(title);
    tempYear.splice(labelIndex, 1);
    newSelectedYear.splice(sectorsIndex, 1);
    setSelectedYears(newSelectedYear);
    setYearLabel(tempYear);
  }

  //==============================================================



  // adding the data to send to the filter
  const selectSector = (id, title) => {


    let newSelectedSectors = [...selectedSectors];
    let sectorIndex = newSelectedSectors.indexOf(id);
    if (sectorIndex === -1) {
      newSelectedSectors.push(id);
    } else {
      newSelectedSectors.splice(sectorIndex, 1);
    }

    let newSectorLabel = [...sectorLabel];
    let sectorIndex1 = newSectorLabel.indexOf(title);
    if (sectorIndex1 === -1) {
      newSectorLabel.push(title);
    } else {
      newSectorLabel.splice(sectorIndex1, 1);
    }

    setSelectedSectors(newSelectedSectors);
    setSectorLabel(newSectorLabel);
    setCurrentPage(1);
  }

  const selectSubsector = (id, title) => {
    let newSelectedSubsectors = [...selectedSubsectors];
    let subsectorIndex = newSelectedSubsectors.indexOf(id);
    if (subsectorIndex === -1) {
      newSelectedSubsectors.push(id);
    } else {
      newSelectedSubsectors.splice(subsectorIndex, 1);
    }

    let newSubsectorLabel = [...subsectorLabel];
    let subsectorIndex1 = newSubsectorLabel.indexOf(title);
    if (subsectorIndex1 === -1) {
      newSubsectorLabel.push(title);
    } else {
      newSubsectorLabel.splice(subsectorIndex1, 1);
    }

    setSelectedSubsectors(newSelectedSubsectors);
    setSubsectorLabel(newSubsectorLabel);
    setCurrentPage(1);
  }

  const selectDonor = (id, title) => {
    let newSelectedDonor = [...selectedDonors];
    let donorIndex = newSelectedDonor.indexOf(id);
    if (donorIndex === -1) {
      newSelectedDonor.push(id);
    } else {
      newSelectedDonor.splice(donorIndex, 1);
    }

    let newDonorLabel = [...donorLabel];
    let donorIndex1 = newDonorLabel.indexOf(title);
    if (donorIndex1 === -1) {
      newDonorLabel.push(title);
    } else {
      newDonorLabel.splice(donorIndex1, 1);
    }
    setSelectedDonors(newSelectedDonor);
    setDonorLabel(newDonorLabel);
    setCurrentPage(1);
  }

  const selectYear = (id, title) => {
    let newSelectedYear = [...selectedYears];
    let sectorIndex = newSelectedYear.indexOf(id);
    if (sectorIndex === -1) {
      newSelectedYear.push(id);
    } else {
      newSelectedYear.splice(sectorIndex, 1);
    }

    let newYearLabel = [...yearLabel];
    let yearIndex1 = newYearLabel.indexOf(title);
    if (yearIndex1 === -1) {
      newYearLabel.push(title);
    } else {
      newYearLabel.splice(yearIndex1, 1);
    }
    setSelectedYears(newSelectedYear);
    setYearLabel(newYearLabel);
    setCurrentPage(1);

  }

  //==============================================================

  const clearFilters = () => {
    setSelectedSectors([])
    setSelectedSubsectors([])
    setSelectedDonors([])
    setSelectedYears([])
    setSectorLabel([])
    setSubsectorLabel([])
    setDonorLabel([])
    setYearLabel([])
  }

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (!e.target.closest(".filter-col")) {
        setShowSectors(false);
        setShowSubsectors(false);
        setShowDonors(false);
        setShowYears(false);
      }
    });
  }, []);


  useEffect(() => {
    setSectorsDataApiOptions({
      url: "sectors",

      callback: (res) => {
        setSectors(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setSubsectorsDataApiOptions({
      url: "subsectors",
      callback: (res) => {
        setSubsectors(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lastPage) {
      setNbOfPages([])

      for (let index = 1; index < lastPage + 1; index++) {
        setNbOfPages((nbOfPages) => [...nbOfPages, index]);
      }
    }
    // eslint-disable-next-line 
  }, [lastPage])


  useEffect(() => {
    setGeneralDataApiOptions({
      url: "reports-settings",
      callback: (res) => {
        setReportsSettings(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [reportsSettings]);

  const { generalDataGlobal } =
    useContext(GlobalState);

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={reportsDataApiOptions} />
      <Api options={sectorsDataApiOptions} />
      <Api options={subsectorsDataApiOptions} />



      {reportsSettings ? (
        <Layout activePage="reports">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo[3].title}</title>
            <meta name="title" content={generalDataGlobal[0]?.seo[3].title} />
            <meta name="description" content={generalDataGlobal[0]?.seo[3].description} />

            <meta property="og:title" content={generalDataGlobal[0]?.seo[3].title} />
            <meta property="og:description" content={generalDataGlobal[0]?.seo[3].description} />
            <meta property="og:image" content={generalDataGlobal[0]?.seo[3].image_path} />

            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[3].title} />
            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[3].description} />
            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[3].image_path} />
          </Helmet>
          <div className="reports-title" animate="">
            <h1>{reportsSettings.title}</h1>
          </div>

          <div className="container pdfs-container" animate="left">
            <div className="row report-filter-row">

              <div className="col-lg-3 position-relative filter-col sectors-col-filter" onClick={() => { setShowSectors(!showSectors); setShowDonors(false); setShowYears(false); setShowSubsectors(false) }}>
                <div className="sectoral-filter d-flex justify-content-between align-items-center" >
                  <div className="sectoral-filter-title">
                    <p>{reportsSettings.sectoral_categories_placeholder}</p>
                  </div>
                  <img
                    src="/assets/images/arrow-down-sign-to-navigate.svg"
                    alt="arrow down"
                    style={{ transform: showSectors ? "rotateX(180deg)" : "" }}
                  />

                </div>



                {
                  showSectors ?
                    <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute" >
                      {
                        sectors ?
                          sectors.map((sector) => (
                            //  eslint-disable-next-line 
                            <a href={null} key={sector.id} style={{ color: sectorLabel.includes(sector.title) ? "#e30613" : "" }} onClick={() => selectSector(sector.id, sector.title)}>
                              <p >
                                {sector.title}
                              </p>
                            </a>

                          ))
                          : null
                      }

                    </div>
                    :
                    null
                }


              </div>
              <div className="col-lg-2 position-relative filter-col subsectors-col-filter" onClick={() => { setShowSubsectors(!showSubsectors); setShowDonors(false); setShowYears(false); setShowSectors(false) }}>
                <div className="sectoral-filter d-flex justify-content-between align-items-center">
                  <div className="sectoral-filter-title">
                    <p>{reportsSettings.sub_sectors_placeholder}</p>
                  </div>
                  <img
                    src="/assets/images/arrow-down-sign-to-navigate.svg"
                    alt="arrow down"
                    style={{ transform: showSubsectors ? "rotateX(180deg)" : "" }}
                  />
                </div>

                {
                  showSubsectors ?
                    <div className="subsectors-dropdown activee dropdown d-flex flex-column align-items-start position-absolute" >
                      {
                        subsectors ?
                          subsectors.map((sector) => (
                            // eslint-disable-next-line
                            <a href={null} key={sector.id} style={{ color: subsectorLabel.includes(sector.title) ? "#e30613" : "" }} onClick={() => selectSubsector(sector.id, sector.title)}>
                              <p >
                                {sector.title}
                              </p>
                            </a>

                          ))
                          : null
                      }

                    </div> : null
                }
              </div>

              <div className="col-lg-2 position-relative filter-col donors-col-filter" onClick={() => { setShowDonors(!showDonors); setShowSubsectors(false); setShowYears(false); setShowSectors(false) }}>
                <div className="sectoral-filter d-flex justify-content-between align-items-center">
                  <div className="sectoral-filter-title">
                    <p>{reportsSettings.donors_placeholder}</p>
                  </div>
                  <img
                    src="/assets/images/arrow-down-sign-to-navigate.svg"
                    alt="arrow down"
                    style={{ transform: showDonors ? "rotateX(180deg)" : "" }}

                  />
                </div>

                {
                  showDonors ?
                    <div className="donors-dropdown activee d-flex flex-column align-items-start position-absolute" >
                      {
                        donors ?
                          donors.map((sector) => (
                            // eslint-disable-next-line
                            <a href={null} key={sector.id} style={{ color: donorLabel.includes(sector.title) ? "#e30613" : "" }} onClick={() => selectDonor(sector.id, sector.title)}>
                              <p >
                                {sector.title}
                              </p>
                            </a>

                          ))
                          : null
                      }

                    </div> : null
                }
              </div>

              <div className="col-lg-2 position-relative filter-col years-col-filter" onClick={() => { setShowYears(!showYears); setShowSubsectors(false); setShowDonors(false); setShowSectors(false) }}>
                <div className="sectoral-filter d-flex justify-content-between align-items-center">
                  <div className="sectoral-filter-title">
                    <p>{reportsSettings.year_placeholder}</p>
                  </div>
                  <img
                    src="/assets/images/arrow-down-sign-to-navigate.svg"
                    alt="arrow down"
                    style={{ transform: showYears ? "rotateX(180deg)" : "" }}

                  />
                </div>

                {
                  showYears ?
                    <div className="years-dropdown activee d-flex flex-column align-items-start position-absolute" >
                      {
                        years ?
                          years.map((year) => (
                            // eslint-disable-next-line
                            <a href={null} key={year.id} style={{ color: yearLabel.includes(year.year) ? "#e30613" : "" }} onClick={() => selectYear(year.id, year.year)}>
                              <p >
                                {year.year}
                              </p>
                            </a>

                          ))
                          : null
                      }

                    </div> : null
                }
              </div>

              {
                showClear ?
                  <div className="col-lg-2 d-flex align-items-start">
                    <p className="clear-all-label" onClick={clearFilters}>{reportsSettings.clear_all}</p>
                  </div>
                  : null
              }



              <div className="row">

                {
                  sectorLabel?.length ?
                    sectorLabel.map(label => (

                      <div className="col-auto ">
                        <div className="sectoral-filter chosen d-flex justify-content-between align-items-center" >
                          <div className="sectoral-filter-title">
                            <p style={{ paddingRight: "10px" }}>{label}</p>
                          </div>
                          <img
                            src="/assets/images/close.svg"
                            alt="remove filter  "
                            className="remove-filter"
                            onClick={() => removeSector(label)}
                          />
                        </div>
                      </div>

                    ))
                    : null
                }

                {
                  subsectorLabel?.length ?
                    subsectorLabel?.map(label => (
                      <div className="col-auto ">

                        <div className="sectoral-filter chosen d-flex justify-content-between align-items-center" >
                          <div className="sectoral-filter-title">
                            <p style={{ paddingRight: "10px" }}>{label}</p>
                          </div>
                          <img
                            src="/assets/images/close.svg"
                            alt="remove filter  "
                            className="remove-filter"
                            onClick={() => removeSubsector(label)}
                          />
                        </div>
                      </div>

                    ))
                    : null
                }

                {
                  donorLabel?.length ?
                    donorLabel?.map(label => (
                      <div className="col-auto ">

                        <div className="sectoral-filter chosen  d-flex justify-content-between align-items-center" >
                          <div className="sectoral-filter-title">
                            <p style={{ paddingRight: "10px" }}>{label}</p>
                          </div>
                          <img
                            src="/assets/images/close.svg"
                            alt="remove filter  "
                            className="remove-filter"
                            onClick={() => removeDonor(label)}
                          />
                        </div>
                      </div>

                    ))
                    : null
                }

                {
                  yearLabel?.length ?
                    yearLabel?.map(label => (
                      <div className="col-auto ">

                        <div className="sectoral-filter chosen  d-flex justify-content-between align-items-center" >
                          <div className="sectoral-filter-title">
                            <p style={{ paddingRight: "10px" }}>{label}</p>
                          </div>
                          <img
                            src="/assets/images/close.svg"
                            alt="remove filter  "
                            className="remove-filter"
                            onClick={() => removeYear(label)}
                          />
                        </div>
                      </div>

                    ))
                    : null
                }
              </div>
            </div>

            {reports?.length ?
              <div className={"row reports-row surveys-wrapper" + (showSurveys ? ' show' : '')}>
                {
                  reports.map((report, index) => (
                    <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6 pdf-col position-relative " >
                      <a href={process.env.REACT_APP_BASE_URL + report.pdf} className="pdf-hover" target="_blank" rel="noreferrer" download>

                        <div className="position-relative">

                          <div className="report-content position-relative shadow " >

                            <div className="pdf-image ratio ratio-1x1">
                              <div className="pdf-overlay position-absolute">
                                <div className=" pdf-title position-relative">
                                  <p className="position-absolute col-8">{report.title}</p>
                                </div>
                                <div className="pdf-download position-absolute">
                                  <img src="/assets/images/download-white.svg" alt="download pdf" />
                                </div>
                              </div>

                              <img src={report.image ? process.env.REACT_APP_BASE_URL + report.image : "/assets/images/PDF.svg"} className={report.image ? "cover-pdf" : "default-pdf"} alt="" />
                            </div>

                          </div>
                        </div>
                      </a>
                      {/* style={{backgroundImage: report.image ? `url(${process.env.REACT_APP_BASE_URL + report.image})` : "url(/assets/images/PDF.svg)"} */}




                    </div>

                  ))
                }
              </div>
              : <div className="no-results container">
                <div className="row" style={{ marginBottom: "90px" }}>
                  <div className="col-12 d-flex justify-content-center no-results">
                    <h1>{reportsSettings.no_results}</h1>
                  </div>
                </div>
              </div>}
          </div>

          {
            reports?.length ?
              <div className="container pagination-container" animate="right">
                <div className="row ">
                  <div className="col-12">
                    <div className="pagination-content d-flex justify-content-center">
                      <div className={"prev-arrow me-2" + (disablePrev ? " low-opacity" : "")}
                        onClick={!disablePrev ? () => setCurrentPage(currentPage - 1) : undefined}
                      >
                        {/* eslint-disable-next-line */}
                        <a href={null}>

                          <img src="/assets/images/next-arrow.svg" alt="" />
                        </a>
                      </div>

                      <div className="pages d-flex">
                        {
                          nbOfPages?.map((page, index) => (
                            <div key={index} className={"page-div d-flex justify-content-center align-items-center me-2 " + (index + 1 === currentPage ? " active-page" : "")}
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {/* eslint-disable-next-line */}
                              <a href={null}>

                                <p>{page}</p>
                              </a>
                            </div>
                          ))
                        }
                      </div>

                      <div className={"next-arrow " + (disableNext ? " low-opacity" : "")}
                        onClick={!disableNext ? () => setCurrentPage(currentPage + 1) : undefined}
                      >
                        {/* eslint-disable-next-line */}
                        <a href={null}>


                          <img src="/assets/images/next-arrow.svg" alt="" />
                        </a>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              :
              null
          }


        </Layout>
      ) : null}
    </>
  );
};

export default Reports;
