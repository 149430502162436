import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";



// import GlobalState from "../contexts/GlobalState";

const About = (props) => {
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const { setIsOpen } = useContext(GlobalState);
  const [aboutData, setAboutData] = useState();
  const [width, setWidth] = useState();

  const { generalDataGlobal } =
  useContext(GlobalState);


  useEffect(() => {
    window.triggerScroll();
  })

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWidth(window.innerWidth);
    });
  }, []);

  let col_index = 0;

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "about-settings",
      callback: (res) => {
        setAboutData(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [aboutData])

  return (
    <>
      <Api options={generalDataApiOptions} />

      {aboutData ? (
        <Layout activePage="about">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo[1].title}</title>
            <meta name="title" content={generalDataGlobal[0]?.seo[1].title} />
            <meta name="description" content={generalDataGlobal[0]?.seo[1].description} />

            <meta property="og:title" content={generalDataGlobal[0]?.seo[1].title} />
            <meta property="og:description" content={generalDataGlobal[0]?.seo[1].description} />
            <meta property="og:image" content={generalDataGlobal[0]?.seo[1].image_path} />

            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[1].title} />
            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[1].description} />
            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[1].image_path} />
          </Helmet>
          <section className="about-earrel-container position-relative" animate="">
            <div className="about-img-container">
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  aboutData?.about_settings.image
                }
                alt=""
              />
            </div>
            <div className="d-none d-sm-block">
              <div className="about-earrel-content col-8 position-absolute d-flex flex-column  align-items-center ">
                <h1 className="about-erreal">
                  {aboutData?.about_settings.image_title}
                </h1>
                <div
                  className="about-erreal-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.about_settings.image_excerpt,
                  }}
                ></div>
              </div>
            </div>
            <div className="mobile-about d-block d-sm-none ">
              <div className="about-earrel-content-mobile d-flex flex-column  align-items-center shadow position-relative">
                <h1 className="about-erreal mobile ">
                  {aboutData?.about_settings.image_title}
                </h1>
                <div
                  className="about-erreal-excerpt mobile"
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.about_settings.image_excerpt,
                  }}
                ></div>
              </div>
            </div>
          </section>

          {aboutData?.partners.length > 0 ? (
            <section className="partners" animate="">
              <div className="partners-title d-flex justify-content-center">
                <h1>{aboutData?.about_settings.partners_title}</h1>
              </div>

              <div className={"container-fluid partners-list"}>
                <div className="row d-flex justify-content-center">
                  <div className="col-xl-6 col-lg-10 col-12 add-pad">
                    <div className="container">
                      <div className="row mobile-row-partners position-relative">
                        {aboutData?.partners.map(
                          (partner, index) => (
                            // eslint-disable-next-line
                            col_index === 3
                              ? (col_index = 1)
                              : // eslint-disable-next-line
                              (col_index = col_index + 1),
                            (
                              <div
                                key={index}
                                className={
                                  "col-lg-4 d-flex flex-column align-items-center justify-content-center shadow-mobile position-relative partner-column"
                                }
                                style={{ paddingBottom: "20px" }}
                              >
                                <div
                                  className={
                                    width > 991.1 ? "ratio ratio-1x1" : ""
                                  }
                                  style={{
                                    paddingTop: width > 991.1 ? "50%" : "",
                                  }}
                                >
                                  <div className="mobile-partners-content d-flex flex-column align-items-center justify-content-center">
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        partner.image
                                      }
                                      alt=""
                                      className="partners-images"
                                    />
                                    <div className="partners-content  d-flex flex-column align-items-center justify-content-center">
                                      <div className=" partners-title-mobile">
                                        <h1>{partner.mobile_title}</h1>
                                      </div>
                                      <div className="partners-excerpt-mobile">
                                        <p>{partner.mobile_excerpt}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}


          <section className="about-lta" animate="">
            <div className="container">
              <div className="row">
                <div
                  className={
                    "col-lg-6 col-12 about-image-col " +
                    (width < 992 ? " ratio ratio-1x1" : "")
                  }
                  style={{ paddingTop: width < 768 ? "100%" : "" }}
                >
                  <img
                    className="about-lta-image"
                    src={
                      process.env.REACT_APP_BASE_URL +
                      aboutData?.about_settings.about_lta_image
                    }
                    alt=""
                  />
                </div>
                <div className="col-lg-6 col-12 shadow">
                  <div className="about-lta-content d-flex flex-column justify-content-center align-items-center">
                    <h1 className="about-lta-title">
                      {aboutData?.about_settings.about_lta_title}
                    </h1>
                    <div
                      className="about-description"
                      dangerouslySetInnerHTML={{
                        __html: aboutData?.about_settings.about_lta_description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>



          {aboutData?.objectives.length > 0 ? (
            <section className="objectives" animate="">
              <div className="objectives-title d-flex justify-content-center">
                <h1>{aboutData?.about_settings.objectives_title}</h1>
              </div>
              <div className="container  d-none d-md-block">
                <div className="row">
                  {aboutData?.objectives.map((obj, index) => (
                    <div
                      key={index}
                      className="col-md-4 d-flex flex-column align-items-center image-col-objectives"
                    >
                      <div className="icon-obj-container shadow">
                        <img
                          src={process.env.REACT_APP_BASE_URL + obj.icon}
                          alt=""
                        />
                      </div>
                      <div className="excerpt-obj-container">
                        <p>{obj.excerpt}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* slider */}
              <div className="container swiper-container d-block d-md-none obj-swiper">
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  loop={true}
                  className="mySwiper"
                >
                  {aboutData?.objectives.map((obj, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="d-flex flex-column align-items-center image-col-objectives"
                      >
                        <div className="icon-obj-container shadow">
                          <img
                            src={process.env.REACT_APP_BASE_URL + obj.icon}
                            alt=""
                          />
                        </div>
                        <div className="excerpt-obj-container swiper">
                          <p>{obj.excerpt}</p>
                        </div>
                      </div>
                    </SwiperSlide>

                  ))}


                </Swiper>
              </div>
            </section>
          ) : null}

          <div className="container research-container" animate="right">
            <div className="row">
              <div className="col-lg-4 research">
                <div className="research-title d-flex justify-content-center">
                  <h1>{aboutData?.about_settings.card1_title}</h1>
                </div>
                <div
                  className="research-description shadow"
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.about_settings.card1_description,
                  }}
                ></div>
              </div>
              <div className="col-lg-4 research">
                <div className="research-title d-flex justify-content-center">
                  <h1>{aboutData?.about_settings.card2_title}</h1>
                </div>
                <div
                  className="research-description shadow"
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.about_settings.card2_description,
                  }}
                ></div>
              </div>
              <div className="col-lg-4 research">
                <div className="research-title d-flex justify-content-center">
                  <h1>{aboutData?.about_settings.card3_title}</h1>
                </div>
                <div
                  className="research-description shadow"
                  dangerouslySetInnerHTML={{
                    __html: aboutData?.about_settings.card3_description,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <section className="beirut-map" animate="left">
            <div className="container">
              <div className="row">
                <div className="col-sm-4 order-2 order-sm-1 beirut-map-content d-flex flex-column justify-content-center">
                  <div className="map-title">
                    <h1>{aboutData?.about_settings.map_title}</h1>
                  </div>
                  <div className="map-description">
                    <p>{aboutData?.about_settings.map_description}</p>
                  </div>
                </div>
                <div className="col-sm-8 order-1 order-sm-2 map-video">
                  <video
                    src={
                      process.env.REACT_APP_BASE_URL +
                      aboutData?.about_settings.video
                    }
                    type="video/mp4"
                    className="video-map"
                    autoPlay
                    muted
                    loop
                  ></video>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      ) : <div className="custom-loader"></div>
      }
    </>
  );
};

export default About;
