import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";



const Surveys = () => {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [sectorsDataApiOptions, setSectorsDataApiOptions] = useState({});
    const [surveysDataApiOptions, setSurveysDataApiOptions] = useState({});
    const { generalDataGlobal } = useContext(GlobalState);



    const [surveysData, setSurveysData] = useState();
    const [sectors, setSectors] = useState();
    const [surveys, setSurveys] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [nbOfPages, setNbOfPages] = useState([]);
    const [lastPage, setLastPage] = useState();
    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(false);

    const [showSectors, setShowSectors] = useState(false);
    const [showYears, setShowYears] = useState(false);

    const [selectedSectors, setSelectedSectors] = useState([]);
    const [sectorLabel, setSectorLabel] = useState([]);

    const [selectedYears, setSelectedYears] = useState([]);
    const [yearLabel, setYearLabel] = useState([]);

    const [showClear, setShowClear] = useState(false);
    const [showSurveys, setShowSurveys] = useState(false);

    const refs = useRef([]);

    // const [loader, setLoader] = useState(false);


    // useEffect(() => {
    //     window.triggerScroll();
    // }, [])





    useEffect(() => {
        document.addEventListener("click", function (e) {
            if (!e.target.closest(".filter-col")) {
                setShowSectors(false);
                setShowYears(false);
            }
        });
    }, []);



    useEffect(() => {
        if (lastPage) {
            setNbOfPages([])
            for (let index = 1; index < lastPage + 1; index++) {
                setNbOfPages((nbOfPages) => [...nbOfPages, index]);
            }
        }
        // eslint-disable-next-line
    }, [lastPage])

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "surveys-settings",
            callback: (res) => {
                setSurveysData(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSectorsDataApiOptions({
            url: "sectors",
            callback: (res) => {
                setSectors(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowSurveys(true);
    }, [surveys]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setShowSurveys(false);

        setSurveysDataApiOptions({
            url: "surveys?page=" + currentPage,
            params: {
                sectors: selectedSectors,
                years: selectedYears
            },
            callback: (res) => {
                setSurveys(res.data.data);

                setLastPage(res.data.last_page);
                if (currentPage === res.data.last_page) {
                    setDisableNext(true);
                } else {
                    setDisableNext(false);
                }

                if (currentPage === 1) {
                    setDisablePrev(true);
                } else {
                    setDisablePrev(false);
                }

                if (selectedSectors.length || selectedYears.length) {
                    setShowClear(true);
                } else {
                    setShowClear(false);
                }

            },
        });
    }, [currentPage, selectedSectors, selectedYears]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (surveys) {

            refs.current = refs.current.slice(0, surveys.length);
        }


    }, [surveys]);


    //========================================filtering=================

    const clearFilters = () => {
        setSelectedSectors([])
        setSectorLabel([])
        setSelectedYears([])
        setYearLabel([])
    }

    const selectSector = (id, title) => {

        let newSelectedSectors = [...selectedSectors];
        let sectorIndex = newSelectedSectors.indexOf(id);
        if (sectorIndex === -1) {
            newSelectedSectors.push(id);
        } else {
            newSelectedSectors.splice(sectorIndex, 1);
        }

        let newSectorLabel = [...sectorLabel];
        let sectorIndex1 = newSectorLabel.indexOf(title);
        if (sectorIndex1 === -1) {
            newSectorLabel.push(title);
        } else {
            newSectorLabel.splice(sectorIndex1, 1);
        }

        setSelectedSectors(newSelectedSectors);
        setSectorLabel(newSectorLabel);
        setCurrentPage(1);
    }

    const selectYear = (id, title) => {
        let newSelectedYear = [...selectedYears];
        let sectorIndex = newSelectedYear.indexOf(id);
        if (sectorIndex === -1) {
            newSelectedYear.push(id);
        } else {
            newSelectedYear.splice(sectorIndex, 1);
        }

        let newYearLabel = [...yearLabel];
        let yearIndex1 = newYearLabel.indexOf(title);
        if (yearIndex1 === -1) {
            newYearLabel.push(title);
        } else {
            newYearLabel.splice(yearIndex1, 1);
        }
        setSelectedYears(newSelectedYear);
        setYearLabel(newYearLabel);
        setCurrentPage(1);

    }


    function getIndex(value, arr, prop) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }


    const removeSector = (title) => {
        let tempSectors = [...sectorLabel];
        let newSelectedSectors = [...selectedSectors];
        let sectorsIndex = newSelectedSectors.indexOf(sectors[getIndex(title, sectors, "title")].id);
        let labelIndex = tempSectors.indexOf(title);
        tempSectors.splice(labelIndex, 1);
        newSelectedSectors.splice(sectorsIndex, 1);
        setSelectedSectors(newSelectedSectors);
        setSectorLabel(tempSectors);

    }

    const removeYear = (title) => {
        let tempYear = [...yearLabel];
        let newSelectedYear = [...selectedYears];
        let sectorsIndex = newSelectedYear.indexOf(generalDataGlobal[0]?.years[getIndex(title, generalDataGlobal[0]?.years, "year")].id);
        let labelIndex = tempYear.indexOf(title);
        tempYear.splice(labelIndex, 1);
        newSelectedYear.splice(sectorsIndex, 1);
        setSelectedYears(newSelectedYear);
        setYearLabel(tempYear);
    }

    useEffect(() => {
        window.triggerScroll();
    })

    return (
        <>
            <Api options={generalDataApiOptions} />
            <Api options={sectorsDataApiOptions} />
            <Api options={surveysDataApiOptions} />


            {
                surveysData ?
                    <Layout activePage="library">
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[5].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[5].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[5].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[5].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[5].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[5].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[5].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[5].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[5].image_path} />
                        </Helmet>
                        <div className="container" animate="right">
                            <div className="row">
                                <div className="col-12">
                                    <div className="surveys-title">
                                        <h1>{surveysData.title}</h1>
                                    </div>
                                </div>
                            </div>

                            <div className="row surveys-description-row">
                                <div className="col-12">
                                    <div className="survey-description" dangerouslySetInnerHTML={{
                                        __html: surveysData.description
                                    }}>

                                    </div>
                                </div>
                            </div>

                            <div className="row survey-filter-row" style={{ marginBottom: (!sectorLabel?.length && !yearLabel?.length) ? "40px" : "" }}>
                                <div className="col-lg-2 position-relative filter-col sectors-col-filter" onClick={() => { setShowSectors(!showSectors); setShowYears(false); }}>
                                    <div className="sectoral-filter d-flex justify-content-between align-items-center" >
                                        <div className="sectoral-filter-title">
                                            <p>{surveysData.sectors_placeholder}</p>
                                        </div>
                                        <img
                                            src="/assets/images/arrow-down-sign-to-navigate.svg"
                                            alt="arrow down"
                                            style={{ transform: showSectors ? "rotateX(180deg)" : "" }}
                                        />

                                    </div>

                                    {
                                        showSectors ?
                                            <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute">
                                                {
                                                    sectors ?
                                                        sectors.map((sector) => (
                                                            // eslint-disable-next-line
                                                            <a key={sector.id} href={null} style={{ color: sectorLabel.includes(sector.title) ? "#e30613" : "" }} onClick={() => { selectSector(sector.id, sector.title); }}>
                                                                <p  >
                                                                    {sector.title}
                                                                </p>
                                                            </a>

                                                        ))
                                                        : null
                                                }

                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                {/* <div className="col-lg-2 d-block d-lg-none">
                                    {
                                        sectorLabel?.length ?
                                            sectorLabel.map(label => (

                                                <div className="sectoral-filter chosen mobile d-flex justify-content-between align-items-center" >
                                                    <div className="sectoral-filter-title">
                                                        <p>{label}</p>
                                                    </div>
                                                    <img
                                                        src="/assets/images/close.svg"
                                                        alt="remove filter  "
                                                        className="remove-filter"
                                                        onClick={() => removeSector(label)}
                                                    />
                                                </div>

                                            ))
                                            : null
                                    }
                                </div> */}
                                <div className="col-lg-2 position-relative filter-col sectors-col-filter" onClick={() => { setShowSectors(false); setShowYears(!showYears); }}>
                                    <div className="sectoral-filter d-flex justify-content-between align-items-center" >
                                        <div className="sectoral-filter-title">
                                            <p>{surveysData.date_placeholder}</p>
                                        </div>
                                        <img
                                            src="/assets/images/arrow-down-sign-to-navigate.svg"
                                            alt="arrow down"
                                            style={{ transform: showYears ? "rotateX(180deg)" : "" }}
                                        />

                                    </div>

                                    {
                                        showYears ?
                                            <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute" >
                                                {
                                                    generalDataGlobal[0]?.years?.length ?
                                                        generalDataGlobal[0]?.years?.map((year) => (
                                                            // eslint-disable-next-line
                                                            <a href={null} key={year.id} style={{ color: yearLabel.includes(year.year) ? "#e30613" : "" }} onClick={() => selectYear(year.id, year.year)} >

                                                                <p >
                                                                    {year.year}
                                                                </p>
                                                            </a>

                                                        ))
                                                        : null
                                                }

                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                {
                                    showClear ?

                                        <div className="col-lg-2 d-flex align-items-center">
                                            <p className="clear-all-label surveys" onClick={clearFilters}>{surveysData.clear_all}</p>
                                        </div>
                                        :
                                        null
                                }

                            </div>

                            <div className="row" style={{ marginBottom: (sectorLabel?.length || yearLabel?.length) ? "20px" : "" }}>

                                {
                                    sectorLabel?.length ?
                                        sectorLabel.map((label, index) => (
                                            <div key={index} className="col-auto ">
                                                <div className="sectoral-filter chosen mobile d-flex justify-content-between align-items-center" >
                                                    <div className="sectoral-filter-title">
                                                        <p style={{ paddingRight: "10px" }}>{label}</p>
                                                    </div>
                                                    <img
                                                        src="/assets/images/close.svg"
                                                        alt="remove filter  "
                                                        className="remove-filter"
                                                        onClick={() => removeSector(label)}
                                                    />
                                                </div>
                                            </div>

                                        ))
                                        : null
                                }
                                {
                                    yearLabel?.length ?
                                        yearLabel?.map((label, index) => (
                                            <div key={index} className="col-lg-2 col-auto">

                                                <div className="sectoral-filter chosen mobile d-flex justify-content-between align-items-center" >
                                                    <div className="sectoral-filter-title">
                                                        <p style={{ paddingRight: "10px" }}>{label}</p>
                                                    </div>
                                                    <img
                                                        src="/assets/images/close.svg"
                                                        alt="remove filter  "
                                                        className="remove-filter"
                                                        onClick={() => removeYear(label)}
                                                    />
                                                </div>
                                            </div>

                                        ))
                                        : null
                                }

                            </div>

                            {

                                surveys?.length ?
                                    <div className={"row surveys surveys-wrapper" + (showSurveys ? ' show' : '')}>
                                        {
                                            surveys?.map((survey, index) => (

                                                <div key={survey.id} className="col-sm-4" >
                                                    <div ref={el => refs.current[index] = el}>
                                                        <div className="survey-image ratio ratio-1x1">

                                                            <div className="pdf-overlay position-absolute"></div>
                                                            <div className=" pdf-title survey position-relative">
                                                                <p className="position-absolute col-auto">{survey.title}</p>
                                                            </div>

                                                            <img src={process.env.REACT_APP_BASE_URL + survey.image} alt="survey-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))

                                        }
                                    </div>
                                    :
                                    <div className="no-results container">
                                        <div className="row" style={{ marginBottom: "90px" }}>
                                            <div className="col-12 d-flex justify-content-center no-results">
                                                <h1>{surveysData.no_results}</h1>
                                            </div>
                                        </div>
                                    </div>


                            }


                            {
                                surveys?.length ?
                                    <div className="container pagination-container">
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="pagination-content d-flex justify-content-center">
                                                    <div className={"prev-arrow me-2" + (disablePrev ? " low-opacity" : "")}
                                                        onClick={!disablePrev ? () => { setCurrentPage(currentPage - 1); } : undefined}
                                                    >
                                                        {/* eslint-disable-next-line*/}
                                                        <a href={null}>

                                                            <img src="/assets/images/next-arrow.svg" alt="" />
                                                        </a>

                                                    </div>

                                                    <div className="pages d-flex">
                                                        {
                                                            nbOfPages?.map((page, index) => (
                                                                <div key={index} className={"page-div d-flex justify-content-center align-items-center me-2 " + (index + 1 === currentPage ? " active-page" : "")}
                                                                    onClick={() => { setCurrentPage(index + 1); }}
                                                                >
                                                                    {/* eslint-disable-next-line*/}
                                                                    <a href={null}>

                                                                        <p>{page}</p>
                                                                    </a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                    <div className={"next-arrow " + (disableNext ? " low-opacity" : "")}
                                                        onClick={!disableNext ? () => { setCurrentPage(currentPage + 1); } : undefined}
                                                    >
                                                        {/* eslint-disable-next-line*/}
                                                        <a href={null}>
                                                            <img src="/assets/images/next-arrow.svg" alt="" />
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div> : null
                            }


                        </div>
                    </Layout>
                    :
                    <div className="custom-loader"></div>
            }



        </>
    )
}

export default Surveys;