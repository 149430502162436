import { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../components/Api";
import Layout from "../components/Layout";
import SubsectorContent from "../components/SubsectorContent";
import SubsectorGrid from "../components/SubsectorGrid";
import GlobalState from "../contexts/GlobalState";

const SingleSectorPage = () => {
	const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
	const { setIsOpen } = useContext(GlobalState);

	const navigate = useNavigate();

	const { slug } = useParams();
	const subsectorContentRef = useRef([]);
	const subsectorContentLgRef = useRef([]);
	const subsectorContentXxlRef = useRef([]);
	const subsectorsRef = useRef([]);
	const subsectorsLgRef = useRef([]);
	const subsectorsXxlRef = useRef([]);
	const [sectorsData, setSectorsData] = useState();
	const [selectedSubsectorId, setSelectedSubsectorId] = useState(0);
	const [subsectorContentHeight, setSubsectorContentHeight] = useState(0);
	const [subsectorContentHeightLg, setSubsectorContentHeightLg] = useState(0);
	const [subsectorContentHeightXxl, setSubsectorContentHeightXxl] = useState(0);

	useEffect(() => {
		window.triggerScroll();
	})

	useEffect(() => {
		setGeneralDataApiOptions({
			url: "sectors/" + slug,
			callback: (res) => {
				setSectorsData(res.data);
				if (res.data.sub_sectors.length) {
					// setSelectedSubsectorId(res.data.sub_sectors[0].id);
				}
				setIsOpen(false);
			},
			catch404: () => {
				navigate("/PageNotFound");
			},
		});
		// eslint-disable-next-line
	}, [slug]);

	useEffect(() => {
		if (sectorsData && sectorsData.sub_sectors.length) {
			subsectorsRef.current = subsectorsRef.current.slice(0, sectorsData.sub_sectors.length);
			subsectorsLgRef.current = subsectorsLgRef.current.slice(0, sectorsData.sub_sectors.length);
			subsectorsXxlRef.current = subsectorsXxlRef.current.slice(0, sectorsData.sub_sectors.length);
			subsectorContentRef.current = subsectorContentRef.current.slice(0, sectorsData.sub_sectors.length);
			subsectorContentLgRef.current = subsectorContentLgRef.current.slice(0, sectorsData.sub_sectors.length);
			subsectorContentXxlRef.current = subsectorContentXxlRef.current.slice(0, sectorsData.sub_sectors.length);
			onSubsectorClick(subsectorContentRef, setSubsectorContentHeight, sectorsData.sub_sectors[0].id, 0);
			onSubsectorClick(subsectorContentLgRef, setSubsectorContentHeightLg, sectorsData.sub_sectors[0].id, 0);
			onSubsectorClick(subsectorContentXxlRef, setSubsectorContentHeightXxl, sectorsData.sub_sectors[0].id, 0);
		}
	}, [sectorsData]);

	const onSubsectorClick = (refs, setHeight, id, i) => {
		setSelectedSubsectorId(id);
		setHeight(refs.current[i].scrollHeight);
	}

	const chunkArray = (array, chunkSize) => {
		let chunckedArray = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunckedArray.push(array.slice(i, i + chunkSize));
		}
		return chunckedArray;
	}

	return (
		<>
			<Api options={generalDataApiOptions} />
			{sectorsData ? (
				<Layout activePage="sectors" singleSectorSlug={slug}>
					<Helmet>
						<title>{sectorsData.og_title ? sectorsData.og_title : sectorsData.title}</title>
						<meta name="title" content={sectorsData.og_title ? sectorsData.og_title : sectorsData.title} />
						<meta name="description" content={sectorsData.og_description ? sectorsData.og_description : ""} />

						<meta property="og:title" content={sectorsData.og_title ? sectorsData.og_title : sectorsData.title} />
						<meta property="og:description" content={sectorsData.og_description ? sectorsData.og_description : ""} />
						<meta property="og:image" content={sectorsData.og_image ? process.env.REACT_APP_BASE_URL + sectorsData.og_image : sectorsData.image} />

						<meta property="twitter:title" content={sectorsData.og_title ? sectorsData.og_title : sectorsData.title} />
						<meta property="twitter:description" content={sectorsData.og_description ? sectorsData.og_description : ""} />
						<meta property="twitter:image" content={sectorsData.og_image ? process.env.REACT_APP_BASE_URL + sectorsData.og_image : process.env.REACT_APP_BASE_URL + sectorsData.image} />
					</Helmet>
					<section className="single-sector-section position-relative" animate="left">
						<div className="container">
							<div className="row justify-content-center align-items-center">
								<div className="col-lg-3 offset-lg-1 single-sector-image-col position-relative">
									<div className="single-sector-image ratio ratio-single-sector">
										<img
											src={process.env.REACT_APP_BASE_URL + sectorsData.image}
											alt=""
										/>
									</div>
								</div>
								<div className="col-lg-7">
									<div className="single-sector-image-title d-flex align-items-center">
										<div className="single-sector-logo">
											<img
												src={process.env.REACT_APP_BASE_URL + sectorsData.icon}
												alt=""
											/>
										</div>
										<div className="single-sector-title">
											<h1>{sectorsData.title}</h1>
										</div>
									</div>
									<div
										className="single-sector-description"
										dangerouslySetInnerHTML={{
											__html: sectorsData.description,
										}}
									></div>
								</div>
							</div>
						</div>
						<div className="single-sector-left-section position-absolute col-lg-3 d-none d-lg-block"></div>

						<div className="single-sector-right-section position-absolute col-1 d-none d-lg-block"></div>

						<div className="single-sector-top-section position-absolute d-block d-lg-none"></div>
					</section>

					{sectorsData?.sub_sectors.length ? (
						<section className="sub-sectors" animate="right">
							<div className="container ">
								<h1 className="sub-sectors-title">SUB-SECTOR</h1>
								{
									chunkArray(sectorsData.sub_sectors, 5).map((chunk, index) => (
										<div key={index}>
											<div className="row justify-content-center d-none d-xxl-flex">
												{
													chunk.map((sub, i) => (
														<SubsectorGrid
															selectedSubsectorId={selectedSubsectorId}
															setSubsectorContentHeight={setSubsectorContentHeightXxl}
															refs={subsectorContentXxlRef}
															onSubsectorClick={onSubsectorClick}
															sub={sub}
															initialIndex={index * 5 + i}
															i={i}
														/>
													))
												}
											</div>
											<div className="d-none d-xxl-block">
												{
													chunk.map((sub, i) => (
														<SubsectorContent
															subsectorContentHeight={subsectorContentHeightXxl}
															refs={subsectorContentXxlRef}
															selectedSubsectorId={selectedSubsectorId}
															sub={sub}
															initialIndex={index * 5 + i}
															i={i}
														/>
													))
												}
											</div>
										</div>
									))
								}
								{
									chunkArray(sectorsData.sub_sectors, 3).map((chunk, index) => (
										<div key={index}>
											<div className="row justify-content-center d-none d-lg-flex d-xxl-none">
												{
													chunk.map((sub, i) => (
														<SubsectorGrid
															sub={sub}
															i={i}
															refs={subsectorContentLgRef}
															onSubsectorClick={onSubsectorClick}
															selectedSubsectorId={selectedSubsectorId}
															setSubsectorContentHeight={setSubsectorContentHeightLg}
															initialIndex={index * 3 + i}
														/>
													))
												}
											</div>
											<div className="d-none d-lg-block d-xxl-none">
												{
													chunk.map((sub, i) => (
														<SubsectorContent
															selectedSubsectorId={selectedSubsectorId}
															sub={sub}
															subsectorContentHeight={subsectorContentHeightLg}
															refs={subsectorContentLgRef}
															i={i}
															initialIndex={index * 3 + i}
														/>
													))
												}
											</div>
										</div>
									))
								}
								<div className="row d-flex d-lg-none">
									{
										sectorsData.sub_sectors.map((sub, i) => (
											<>
												<SubsectorGrid
													selectedSubsectorId={selectedSubsectorId}
													setSubsectorContentHeight={setSubsectorContentHeight}
													onSubsectorClick={onSubsectorClick}
													refs={subsectorContentRef}
													sub={sub}
													i={i}
													initialIndex={i}
												/>
												<SubsectorContent
													subsectorContentHeight={subsectorContentHeight}
													selectedSubsectorId={selectedSubsectorId}
													refs={subsectorContentRef}
													sub={sub}
													initialIndex={i}
													i={i}
												/>
											</>
										))
									}
								</div>
							</div>
						</section>
					) : null}
				</Layout>
			) : null
			}
		</>
	);
};

export default SingleSectorPage;
