import { useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import SingleSector from "../components/SingleSector";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";




const Home = () => {

    SwiperCore.use([Autoplay])

    const { generalDataGlobal } =
        useContext(GlobalState);

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [homeSettings, setHomeSettings] = useState();
    const [homeSwiper, setHomeSwiper] = useState();
    const [providers, setProviders] = useState();
    const [sectors, setSectors] = useState();
    const [reports, setReports] = useState();

    const [showProvider, setShowProvider] = useState(false);
    const [provider, setProvider] = useState();

    useEffect(() => {
        if (showProvider) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [showProvider]);


    useEffect(() => {
        window.triggerScroll();
    })

    useEffect(() => {
        if (provider) {
            document.addEventListener('keydown', function (e) {
                e = e || window.event;
                // eslint-disable-next-line
                if (e.keyCode == '27') {
                    // right arrow
                    setShowProvider(false)
                }
            });
        }
        // eslint-disable-next-line
    }, [provider])

    const chooseProvider = (index) => {
        setProvider(providers[index]);
        setShowProvider(true);
    }

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "home-settings",
            callback: (res) => {
                setHomeSettings(res.data.home_settings)
                setHomeSwiper(res.data.home_swiper)
                setProviders(res.data.providers)
                setSectors(res.data.sectors)
                setReports(res.data.reports)

            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps




    return (

        <>
            <Api options={generalDataApiOptions} />

            {
                homeSettings ?
                    <Layout>
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[0].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[0].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[0].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[0].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[0].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[0].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[0].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[0].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[0].image_path} />
                        </Helmet>
                        <section className="home-banner-wrapper" animate="">
                            <div className="home-banner-content position-relative">
                                <div className="home-video-container position-absolute">
                                    <video
                                        src={
                                            process.env.REACT_APP_BASE_URL +
                                            homeSettings.video
                                        }
                                        type="video/mp4"
                                        className="home-video"
                                        autoPlay
                                        muted
                                        loop
                                    ></video>
                                </div>
                                <div className="container h-100 position-relative">
                                    <div className="row h-100">
                                        <div className="col-lg-5 offset-lg-7 h-100">
                                            <div className="home-banner-desc">
                                                <div className="home-banner-title">
                                                    <h1>{homeSettings.title}</h1>
                                                </div>
                                                <div className="home-banner-excerpt">
                                                    <p>{homeSettings.excerpt}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid home-swiper-container position-relative d-none d-lg-block">
                                    <div className="row">
                                        <div className="col-lg-9 offset-lg-3 position-relative">
                                            <Swiper modules={[Navigation]} slidesPerView={3} loop={true} navigation={{
                                                nextEl: '.swiper-button-next1',
                                                prevEl: '.swiper-button-prev1'
                                            }}

                                                className="home-swiper">
                                                {
                                                    homeSwiper?.length ?
                                                        homeSwiper.map((el, index) => (
                                                            <SwiperSlide key={el.id}>
                                                                <div className="swiper-element d-flex flex-column align-items-center shadow">
                                                                    <div className="swiper-icon-home shadow">
                                                                        <img src={process.env.REACT_APP_BASE_URL + el.icon} alt="" />
                                                                    </div>
                                                                    <div className="swiper-title-excerpt">

                                                                        <div className="swiper-title-home">
                                                                            <h1>{el.title}</h1>
                                                                        </div>
                                                                        <div className="swiper-excerpt-home">
                                                                            <p>{el.excerpt}</p>
                                                                        </div>
                                                                        <div className="url-home-swiper">
                                                                            <a href={el.url} className="read-more">
                                                                                {el.url_label}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))
                                                        : null
                                                }
                                            </Swiper>
                                            <div className="swiper-button-prev1 home">
                                                <button className="prev-article">
                                                    <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                </button>
                                            </div>

                                            <div className="swiper-button-next1 home">
                                                <button className="next-article">
                                                    <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="container home-swiper-container position-relative d-none d-md-block d-lg-none" animate="">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-2 position-relative">
                                    <Swiper modules={[Navigation]} slidesPerView={2} loop={true} navigation={{
                                        nextEl: '.swiper-button-next1',
                                        prevEl: '.swiper-button-prev1'
                                    }}

                                        className="home-swiper">
                                        {
                                            homeSwiper?.length ?
                                                homeSwiper.map((el, index) => (
                                                    <SwiperSlide key={el.id}>
                                                        <div className="swiper-element d-flex flex-column align-items-center shadow">
                                                            <div className="swiper-icon-home shadow">
                                                                <img src={process.env.REACT_APP_BASE_URL + el.icon} alt="" />
                                                            </div>
                                                            <div className="swiper-title-excerpt">

                                                                <div className="swiper-title-home">
                                                                    <h1>{el.title}</h1>
                                                                </div>
                                                                <div className="swiper-excerpt-home">
                                                                    <p>{el.excerpt}</p>
                                                                </div>
                                                                <div className="url-home-swiper ">
                                                                    <a href={el.url} className="read-more">
                                                                        {el.url_label}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                                : null
                                        }
                                    </Swiper>
                                    <div className="swiper-button-prev1 home">
                                        <button className="prev-article">
                                            <img src="/assets/images/arrow-right-white.svg" alt="" />
                                        </button>
                                    </div>

                                    <div className="swiper-button-next1 home">
                                        <button className="next-article">
                                            <img src="/assets/images/arrow-right-white.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container sections-container d-block d-md-none" animate="">
                            <div className="row">
                                {
                                    homeSwiper?.length ?
                                        homeSwiper.map((el, index) => (
                                            <div className="col-12">

                                                <div className="home-sections d-flex align-items-center shadow">
                                                    <div className="swiper-icon-home shadow">
                                                        <img src={process.env.REACT_APP_BASE_URL + el.icon} alt="" />
                                                    </div>
                                                    <div className="swiper-title-excerpt">

                                                        <div className="swiper-title-home">
                                                            <h1>{el.title}</h1>
                                                        </div>
                                                        <div className="swiper-excerpt-home">
                                                            <p>{el.excerpt}</p>
                                                        </div>
                                                        <div className="url-home-swiper">
                                                            <a href={el.url} className="read-more">
                                                                {el.url_label}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null
                                }
                            </div>
                        </div>
                        <section className="home-about-section" animate="">
                            <div className="container">
                                <div className="row justify-content-center align-items-center position-relative">
                                    <div className="col-md-6 col-lg-5 col-xl-4 home-about-img">
                                        <img src={process.env.REACT_APP_BASE_URL + homeSettings.about_image} alt="" />
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-4 home-about-description-col">
                                        <div className="about-wrapper">

                                            <div className="home-about-description shadow">
                                                <div className="home-about-title">
                                                    <h1>{homeSettings.about_title}</h1>
                                                </div>
                                                <div className="home-about-excerpt">
                                                    <p>{homeSettings.about_excerpt}</p>
                                                </div>
                                            </div>
                                            <div className="about-read-more position-absolute d-none d-md-block">
                                                <Link to="/about" className="read-more">
                                                    {homeSettings.about_read_more}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="read-more home d-flex justify-content-center d-block d-md-none">
                                    <Link to="/about" className="read-more">
                                        {homeSettings.about_read_more}
                                    </Link>
                                </div>
                            </div>
                        </section>

                        <section className="methods-home" animate="">
                            <div className="methods-wrapper position-relative">
                                <div className="home-methods-overlay position-absolute"></div>
                                <div className="methods-image position-absolute">
                                    <img src={process.env.REACT_APP_BASE_URL + homeSettings.methods_image} alt="" />
                                </div>

                                <div className="container methods-container">
                                    <div className="row methods-row">
                                        <div className="col-lg-4 order-3 order-lg-1 method-col">
                                            <div className="method-content one" >
                                                <div className="d-flex flex-column align-items-center">

                                                    <div className="method-title">
                                                        <h1>{homeSettings.tracker_method_title}</h1>
                                                    </div>
                                                    <div className="method-description tracker-desc" dangerouslySetInnerHTML={{
                                                        __html: homeSettings.tracker_description
                                                    }}></div>
                                                </div>
                                                <div className="read-more methods">
                                                    <Link to="/tracker-methodology" className="read-more ">
                                                        {homeSettings.about_read_more}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 d-flex align-items-end order-2 method-col">
                                            <div className="method-content two">
                                                <div className="d-flex flex-column align-items-center">

                                                    <div className="method-title">
                                                        <h1>{homeSettings.surveys_title}</h1>
                                                    </div>
                                                    <div className="method-description" dangerouslySetInnerHTML={{
                                                        __html: homeSettings.surveys_description
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 order-1 order-lg-3 method-col">
                                            <div className="method-content kll one">
                                                <div className="d-flex flex-column align-items-center">

                                                    <div className="method-title">
                                                        <h1>{homeSettings.kll_title}</h1>
                                                    </div>
                                                    <div className="method-description" dangerouslySetInnerHTML={{
                                                        __html: homeSettings.kll_description
                                                    }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="providers-home" animate="">
                            <div className="providers-wrapper position-relative">
                                <div className="providers-image position-absolute">
                                    <img src={process.env.REACT_APP_BASE_URL + homeSettings.providers_image} alt="" />
                                </div>

                                <div className="container-fluid providers-container">
                                    <div className="row">
                                        <div className="col-lg-7 offset-lg-5">

                                            <div className="providers-content position-relative d-flex flex-column align-items-center d-none d-lg-flex">
                                                <div>
                                                    <div className="providers-title">
                                                        <h1>{homeSettings.providers_title}</h1>
                                                    </div>
                                                    <div className="providers-desc">
                                                        <p>{homeSettings.providers_excerpt}</p>
                                                    </div>

                                                </div>
                                                {
                                                    (providers?.length <= 3) ?
                                                        <div className="container position-absolute bottom-0">
                                                            <div className="row providers-row justify-content-center">
                                                                {providers.map((el, index) => (
                                                                    <div className="col-xl-3 col-md-4 provider-icon-col" >
                                                                        <img src={process.env.REACT_APP_BASE_URL + el.image} className="shadow" alt="provider" onClick={() => chooseProvider(index)} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div> :
                                                        <div className="container position-absolute bottom-0">
                                                            <div className="row providers-row swiperr justify-content-center">
                                                                <div className="col-xl-10 col-md-12">

                                                                    <Swiper className="providers-swiper p-4" spaceBetween={30} modules={[Autoplay]} autoplay={true} slidesPerView={3}>
                                                                        {providers.map((el, index) => (
                                                                            <SwiperSlide>
                                                                                <div className="provider-icon-col ratio ratio-4x3" >
                                                                                    <img src={process.env.REACT_APP_BASE_URL + el.image} className="shadow" alt="provider" onClick={() => chooseProvider(index)} />
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        ))}
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                        </div>



                                                }


                                            </div>
                                            {/* {
                                                providers?.length > 3 ?
                                                    <div className="col-12">
                                                        <Swiper modules={[Navigation]} slidesPerView={3}  loop={true} navigation={{
                                                            nextEl: '.swiper-button-next1',
                                                            prevEl: '.swiper-button-prev1'
                                                        }}

                                                            className="providers-swiper">
                                                            {
                                                                providers?.length ?
                                                                    providers.map((el, index) => (
                                                                        <SwiperSlide key={el.id}>

                                                                            <img className="provider-img-swiper" src={process.env.REACT_APP_BASE_URL + el.image} alt="" />

                                                                        </SwiperSlide>
                                                                    ))
                                                                    : null
                                                            }
                                                        </Swiper>
                                                    </div>
                                                    : null

                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container" animate="">
                                <div className="row">

                                    <div className="providers-content position-relative d-flex flex-column align-items-center d-block d-lg-none">
                                        <div className="prov-content">
                                            <div className="providers-title">
                                                <h1>{homeSettings.providers_title}</h1>
                                            </div>
                                            <div className="providers-desc">
                                                <p>{homeSettings.providers_excerpt}</p>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className="container d-none d-sm-block d-lg-none">

                                {
                                    (providers?.length <= 3) ?
                                        <div className="row providers-row">
                                            {providers.map((el, index) => (
                                                <div className="col-sm-4 providers-mobile">
                                                    <img src={process.env.REACT_APP_BASE_URL + el.image} className="shadow" alt="provider" onClick={() => chooseProvider(index)} />
                                                </div>
                                            ))}
                                        </div> :
                                        <div className="row providers-row mobile-swiperr">
                                            <Swiper className="providers-swiper p-3" modules={[Autoplay]} slidesPerView={1} spaceBetween={20} autoplay={true} breakpoints={{
                                                576: {
                                                    slidesPerView: 3,
                                                },
                                            }}>
                                                {providers.map((el, index) => (
                                                    <SwiperSlide>

                                                        <div className="providers-mobile ratio ratio-4x3">
                                                            <img src={process.env.REACT_APP_BASE_URL + el.image} className="shadow" alt="provider" onClick={() => chooseProvider(index)} />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                }
                            </div>

                            <div className="container d-block d-sm-none">

                                {
                                    (providers?.length) ?
                                        <div className="row providers-row">
                                            {providers.map((el, index) => (
                                                <div className="col-sm-4 mb-3 providers-mobile">
                                                    <img src={process.env.REACT_APP_BASE_URL + el.image} className="shadow" alt="provider" onClick={() => chooseProvider(index)} />
                                                </div>
                                            ))}
                                        </div> : null
                                }
                            </div>
                        </section>

                        <section className="sectors-home" >
                            <div className="sectors-wrapper-home position-relative d-flex justify-content-center align-items-center">
                                <div className="home-sectors-overlay position-absolute"></div>
                                <div className="home-sector-image position-absolute" style={{ backgroundImage: "url(" + process.env.REACT_APP_BASE_URL + homeSettings.image_sectors + ")" }}></div>

                                <div className="container sectors-container-home sectors-section d-none d-sm-block" animate="">
                                    <div className="sectors-home-title front position-relative">
                                        <h1>{homeSettings.title_sectors}</h1>
                                    </div>
                                    <div className="row home-sectors-row">
                                        {sectors
                                            ? sectors?.map((sector, index) => (
                                                index < 6 ?

                                                    <div className="col-xl-4 col-sm-6 sectors-col">
                                                        <Link to={"/sectors/" + sector.slug}>
                                                            <SingleSector
                                                                page={"home"}
                                                                key={index}
                                                                title={sector.title}
                                                                excerpt={sector.excerpt}
                                                                icon={sector.icon}
                                                            ></SingleSector>
                                                        </Link>
                                                    </div>
                                                    : null
                                            ))
                                            : null}
                                    </div>
                                    <Link to="/sectors">

                                        <div className="btn-container home d-flex justify-content-center">
                                            <button className="view-btn"> {homeSettings.learn_more_sectors} </button>
                                        </div>
                                    </Link>
                                </div>

                                <div className="container d-block d-sm-none">
                                    <div className="sectors-home-title  front position-relative">
                                        <h1 className="mb-0">{homeSettings.title_sectors}</h1>
                                    </div>
                                    <Swiper className="home-sectors-container py-5" modules={Autoplay} autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }} slidesPerView={1.2} spaceBetween={15} centeredSlides={true} loop={true}
                                    >
                                        {sectors
                                            ? sectors?.map((sector, index) => (
                                                index < 6 ?
                                                    <SwiperSlide>


                                                        <Link to={"/sectors/" + sector.slug}>
                                                            <SingleSector
                                                                page={"home"}
                                                                key={index}
                                                                title={sector.title}
                                                                excerpt={sector.excerpt}
                                                                icon={sector.icon}
                                                            ></SingleSector>
                                                        </Link>
                                                    </SwiperSlide>
                                                    : null
                                            ))
                                            : null}
                                    </Swiper>
                                    <Link to="/sectors" className="sectors-link">

                                        <div className="btn-container home d-flex d-sm-none justify-content-center">
                                            <button className="view-btn"> {homeSettings.learn_more_sectors} </button>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </section>

                        <section className="home-reports">
                            <div className="home-reports-wrapper">
                                <div className="container">
                                    <div className="row justify-content-center flex-column align-items-center">
                                        <div className="col position-relative d-flex justify-content-center">
                                            <div className="home-reports-title">
                                                <h1>{homeSettings.reports_title}</h1>
                                            </div>
                                            <div className="view-all-reports position-absolute d-none d-sm-block">
                                                <Link className="read-more" to="/reports">
                                                    {homeSettings.reports_view_all}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 d-flex justify-content-center">
                                            <div className="home-reports-excerpt">
                                                <p>{homeSettings.reports_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container">

                                <div className="row home-reports-row d-none d-sm-flex">
                                    {
                                        reports?.length ?

                                            reports.map((report, index) => (
                                                index < 6 ?
                                                    <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6 pdf-col position-relative " >
                                                        <a href={process.env.REACT_APP_BASE_URL + report.pdf} className="pdf-hover" target="_blank" rel="noreferrer" download>

                                                            <div className="position-relative">

                                                                <div className="report-content position-relative shadow " >

                                                                    <div className="pdf-image ratio ratio-1x1" style={{ backgroundColor: "white" }}>
                                                                        <div className="pdf-overlay position-absolute">
                                                                            <div className=" pdf-title position-relative">
                                                                                <p className="position-absolute col-8">{report.title}</p>
                                                                            </div>
                                                                            <div className="pdf-download position-absolute">
                                                                                <img src="/assets/images/download-white.svg" alt="download pdf" />
                                                                            </div>
                                                                        </div>

                                                                        <img src={report.image ? process.env.REACT_APP_BASE_URL + report.image : "/assets/images/PDF.svg"} className={report.image ? "cover-pdf" : "default-pdf"} alt="" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </a>
                                                        {/* style={{backgroundImage: report.image ? `url(${process.env.REACT_APP_BASE_URL + report.image})` : "url(/assets/images/PDF.svg)"} */}
                                                    </div>
                                                    : null
                                            )) : null
                                    }
                                </div>
                            </div>

                            <div className="container swiper-reports-container">
                                <div className="row home-reports-row swiper d-block d-sm-none">
                                    <Swiper className="home-sectors-container py-5" slidesPerView={1.2} spaceBetween={15} centeredSlides={true} loop={true}>
                                        {reports
                                            ? reports?.map((report, index) => (
                                                index < 6 ?
                                                    <SwiperSlide>
                                                        <a href={process.env.REACT_APP_BASE_URL + report.pdf} className="pdf-hover" target="_blank" rel="noreferrer" download>

                                                            <div className="position-relative">

                                                                <div className="report-content position-relative shadow " >

                                                                    <div className="pdf-image ratio ratio-1x1" style={{ backgroundColor: "white" }}>
                                                                        <div className="pdf-overlay position-absolute">
                                                                            <div className=" pdf-title position-relative">
                                                                                <p className="position-absolute col-8">{report.title}</p>
                                                                            </div>
                                                                            <div className="pdf-download position-absolute">
                                                                                <img src="/assets/images/download-white.svg" alt="download pdf" />
                                                                            </div>
                                                                        </div>

                                                                        <img src={report.image ? process.env.REACT_APP_BASE_URL + report.image : "/assets/images/PDF.svg"} className={report.image ? "cover-pdf" : "default-pdf"} alt="" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </a>
                                                    </SwiperSlide>
                                                    : null
                                            ))
                                            : null}
                                    </Swiper>
                                </div>
                            </div>
                            <div className="view-all-reports-mobile d-bloc d-sm-none">
                                <Link className="read-more" to="/reports">
                                    {homeSettings.reports_view_all}
                                </Link>
                            </div>
                        </section>

                        {
                            showProvider ?
                                provider.description ?
                                    <div className="provider-popup position-fixed h-100 w-100 top-0 start-0">
                                        <div className="h-100 w-100 p-3">
                                            <div className="d-flex align-items-center justify-content-center h-100 p-2 p-sm-5">
                                                <div className="provider-content">

                                                    <div className="provider-image">
                                                        <img src={process.env.REACT_APP_BASE_URL + provider?.image} alt="" />
                                                    </div>
                                                    <h1 className="provider-popup-title">
                                                        {provider?.title}
                                                    </h1>
                                                    <div className="provider-description" dangerouslySetInnerHTML={{
                                                        __html: provider?.description
                                                    }}>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"cancel-modal position-absolute top-0 end-0"}
                                            onClick={() => setShowProvider(false)}>
                                            {/* eslint-disable-next-line*/}
                                            <a href={null}>

                                                <img src="/assets/images/close-modal.svg" alt="" />
                                            </a>

                                        </div>
                                    </div>
                                    : null
                                : null
                        }

                    </Layout>
                    :
                    <div className="custom-loader"></div>
            }
        </>
    )
}

export default Home;