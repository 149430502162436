import { useContext, useEffect, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


import { Autoplay, Navigation } from "swiper";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import GlobalState from "../contexts/GlobalState";


const Library = () => {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [librarySettings, setLibrarySettings] = useState();
    const [featuredSurveys, setFeaturedSurveys] = useState([]);
    const [featuredArticles, setFeaturedArticles] = useState([]);



    useEffect(() => {
        setGeneralDataApiOptions({
            url: "library",
            callback: (res) => {
                setLibrarySettings(res.data.library_settings);
                setFeaturedSurveys(res.data.featured_surveys);
                setFeaturedArticles(res.data.featured_articles);

            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.triggerScroll();
    })

    const { generalDataGlobal } =
    useContext(GlobalState);

    return (
        <>
            <Api options={generalDataApiOptions} />

            {
                librarySettings ?
                    <Layout activePage="library">
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[4].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[4].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[4].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[4].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[4].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[4].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[4].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[4].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[4].image_path} />
                        </Helmet>
                        <div className="library-title" animate="">
                            <h1>{librarySettings.title}</h1>
                        </div>

                        <section className="featured-surveys" animate="left">
                            <div className="featured-surveys-wrapper">
                                <div className="container" >
                                    <div className={"row align-items-center " + (featuredSurveys ? (featuredSurveys.length <= 3 ? "pad-bot" : "") : "")}>
                                        <div className="col-lg-4 col-xl-5">
                                            <div className="featured-surveys-content">
                                                <div className="featured-surveys-title">
                                                    <h1>{librarySettings.surveys_titlw}</h1>
                                                </div>
                                                <div className="featured-surveys-excerpt">
                                                    <p>
                                                        {librarySettings.surveys_excerpt}
                                                    </p>
                                                </div>
                                                <Link to="/surveys">

                                                    <div className="btn-container">
                                                        <button className="view-btn"> {librarySettings.view_all} </button>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        {
                                            featuredSurveys ?
                                                featuredSurveys.length <= 3 ?
                                                    featuredSurveys.map((survey, index) => (
                                                        <div key={survey.id} className={"col-lg-2 col-sm-4 " + (index === 0 ? "offset-lg-1" : "")}>

                                                            <div className="survey-img ratio ratio-1x1">
                                                                <div className="pdf-overlay library-survey position-absolute"></div>
                                                                <div className=" pdf-title survey position-relative">
                                                                    <p className="position-absolute col-auto">{survey.title}</p>
                                                                </div>
                                                                <img src={process.env.REACT_APP_BASE_URL + survey.image} alt="" />
                                                            </div>

                                                        </div>
                                                    ))
                                                    :
                                                    <div className="col-lg-6 swiper-col position-relative">
                                                        <Swiper
                                                            modules={[Navigation, Autoplay]}
                                                            slidesPerView={1}
                                                            spaceBetween={30}
                                                            loop={true}
                                                            autoplay={{
                                                                delay: 2500,
                                                                disableOnInteraction: false,
                                                            }}
                                                            navigation={{
                                                                nextEl: '.swiper-button-next',
                                                                prevEl: '.swiper-button-prev'
                                                            }}
                                                            breakpoints={{
                                                                577: {
                                                                    slidesPerView: 2,
                                                                    spaceBetween: 20,
                                                                },
                                                                767: {
                                                                    slidesPerView: 3,
                                                                    spaceBetween: 20,
                                                                },
                                                                991: {
                                                                    slidesPerView: 2,
                                                                },
                                                                1200: {
                                                                    slidesPerView: 3,

                                                                }
                                                            }}

                                                            className="mySwiper">
                                                            {

                                                                featuredSurveys.map((survey) => (
                                                                    <SwiperSlide key={survey.id}>
                                                                        <div className="survey-img ratio ratio-1x1">
                                                                            <div className="pdf-overlay library-survey position-absolute"></div>
                                                                            <div className=" pdf-title survey position-relative">
                                                                                <p className="position-absolute col-auto">{survey.title}</p>
                                                                            </div>
                                                                            <img src={process.env.REACT_APP_BASE_URL + survey.image} alt="" />
                                                                        </div>

                                                                    </SwiperSlide>

                                                                ))
                                                            }
                                                        </Swiper>
                                                        <div className="swiper-button-prev">
                                                            <button className="prev-survey">
                                                                <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                            </button>
                                                        </div>

                                                        <div className="swiper-button-next">
                                                            <button className="next-survey">
                                                                <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                            </button>

                                                        </div>
                                                    </div>


                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="featured-articles" animate="right">
                            <div className="featured-articles-wrapper" style={{ paddingBottom: featuredArticles ? (featuredArticles.length <= 2 ? "90px" : "") : "" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 d-flex flex-column justify-content-center">
                                            <div className="featured-articles-content">
                                                <div className="featured-article-title">
                                                    <h1>
                                                        {librarySettings.articles_title}
                                                    </h1>
                                                </div>
                                                <div className="featured-article-excerpt">
                                                    <p>
                                                        {librarySettings.articles_exceprt}
                                                    </p>
                                                </div>
                                                <Link to="/articles">
                                                    <div className="btn-container">
                                                        <button className="view-btn reverted"> {librarySettings.view_all} </button>
                                                    </div>
                                                </Link>
                                            </div>

                                        </div>
                                        {
                                            featuredArticles ?
                                                featuredArticles.length <= 2 ?
                                                    featuredArticles.map((article, index) => (
                                                        <div key={article.id} className={"col-lg-4 col-sm-6  d-flex flex-column " + (index === 0 ? "offset-lg-1" : "")}>
                                                            <div className="article-content h-100 d-flex flex-column shadow">
                                                                <div className="article-image ratio ratio-16x9">
                                                                    <img src={process.env.REACT_APP_BASE_URL + article.image} alt="" />
                                                                </div>
                                                                <div className="d-flex flex-column h-100">

                                                                    <div className="d-flex flex-column h-100">

                                                                        <div className="title-date-container d-flex justify-content-between align-items-center flex-grow-1">
                                                                            <div className="article-title col-8">
                                                                                <h1>
                                                                                    {article.title}
                                                                                </h1>
                                                                            </div>
                                                                            <div className="article-date">
                                                                                <p>
                                                                                    {article.date}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="article-excerpt flex-grow-1">
                                                                            <p>
                                                                                {article.excerpt}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <Link to={"/articles/" + article.slug}>
                                                                        <div className="read-more">
                                                                            <p>
                                                                                {librarySettings.read_more}
                                                                            </p>
                                                                        </div>
                                                                    </Link>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    )) : <div className="col-lg-8 article-swiper position-relative">
                                                        <Swiper
                                                            // autoplay={{
                                                            //     delay: 2500,
                                                            //     disableOnInteraction: false,
                                                            // }}
                                                            loop={true}
                                                            modules={[Navigation, Autoplay]}
                                                            slidesPerView={1}
                                                            style={{ paddingBottom: "90px" }}
                                                            navigation={{
                                                                nextEl: '.swiper-button-next1',
                                                                prevEl: '.swiper-button-prev1'
                                                            }}

                                                            breakpoints={{
                                                                767: {
                                                                    slidesPerView: 2,
                                                                },
                                                            }}

                                                            className="articlesSwiper">
                                                            {

                                                                featuredArticles.map((article) => (
                                                                    <SwiperSlide key={article.id}>

                                                                        <div className="article-content h-100 d-flex flex-column shadow">
                                                                            <div className="article-image ratio ratio-16x9">
                                                                                <img src={process.env.REACT_APP_BASE_URL + article.image} alt="" />
                                                                            </div>
                                                                            <div className="d-flex flex-column h-100">

                                                                                <div className="d-flex flex-column h-100">

                                                                                    <div className="title-date-container d-flex justify-content-between align-items-center flex-grow-1">
                                                                                        <div className="article-title col-8">
                                                                                            <h1>
                                                                                                {article.title}
                                                                                            </h1>
                                                                                        </div>
                                                                                        <div className="article-date">
                                                                                            <p>
                                                                                                {article.date}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="article-excerpt flex-grow-1">
                                                                                        <p>
                                                                                            {article.excerpt}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <Link to={"/articles/" + article.slug}>
                                                                                    <div className="read-more">
                                                                                        <p>
                                                                                            {librarySettings.read_more}
                                                                                        </p>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>

                                                                ))
                                                            }
                                                        </Swiper>
                                                        <div className="swiper-button-prev1">
                                                            <button className="prev-article">
                                                                <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                            </button>
                                                        </div>

                                                        <div className="swiper-button-next1">
                                                            <button className="next-article">
                                                                <img src="/assets/images/arrow-right-white.svg" alt="" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                : null


                                        }
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="gallery-section position-relative d-flex align-items-end justify-content-center" animate="">
                            <div className="gallery-image-container position-absolute" >
                                {/* <img src={process.env.REACT_APP_BASE_URL + librarySettings.gallery_image} alt="" /> */}
                            </div>
                            <div className="gallery-overlay position-absolute" style={{ backgroundImage: "url(" + process.env.REACT_APP_BASE_URL + librarySettings.gallery_image + ")" }}></div>
                            <div className="gallery-content d-flex flex-column align-items-center justify-content-center h-100">
                                <div>

                                    <div className="gallery-title">
                                        <h1>{librarySettings.gallery_title}</h1>
                                    </div>
                                    <div className="gallery-subtitle">
                                        <p>{librarySettings.gallery_subtitlte}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-container gallery position-absolute">
                                <Link to="/gallery">
                                    <button className="view-btn gallery"> {librarySettings.view_all} </button>
                                </Link>
                            </div>
                        </section>
                    </Layout>
                    :
                    <div className="custom-loader"></div>
            }

        </>
    )
}
export default Library;