import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";

const Gallery = () => {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [eventsDataApiOptions, setEventsDataApiOptions] = useState({});
    const [galleryDataApiOptions, setGalleryDataApiOptions] = useState({});

    const { generalDataGlobal } = useContext(GlobalState);


    const [gallerySettings, setGallerySettings] = useState();

    const [events, setEvents] = useState();
    const [showEvents, setShowEvents] = useState(false);
    const [showYears, setShowYears] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nbOfPages, setNbOfPages] = useState([]);
    const [lastPage, setLastPage] = useState();
    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(false);
    const [gallery, setGallery] = useState();
    const [showGallery, setShowGallery] = useState(false);
    // const [chosenImage, setChosenImage] = useState();
    const [chosenIndex, setChosenIndex] = useState();

    const [selectedEvents, setSelectedEvents] = useState([]);
    const [eventLabel, setEventLabel] = useState([]);

    const [selectedYears, setSelectedYears] = useState([]);
    const [yearLabel, setYearLabel] = useState([]);

    const [format, setFormat] = useState();

    const [showClear, setShowClear] = useState(false);

    // const getChosenIndex = () => {
    //     return chosenIndex;
    // }

    useEffect(() => {
        if (gallery) {
            document.addEventListener('keydown', function (e) {
                e = e || window.event;

                setChosenIndex(prevIndex => {
                    // eslint-disable-next-line
                    if (e.keyCode == '37') {
                        // left arrow
                        if (prevIndex > 0) {
                            return prevIndex - 1;
                        }
                    }
                    // eslint-disable-next-line
                    else if (e.keyCode == '39') {
                        // right arrow
                        if (prevIndex < (gallery.length)) {
                            return prevIndex + 1;
                        }
                    }
                    // eslint-disable-next-line
                    else if (e.keyCode == '27') {
                        // escape
                        return
                    }
                    return prevIndex;
                })
            });
        }
        // eslint-disable-next-line
    }, [gallery])



    function getIndex(value, arr, prop) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    const removeEvent = (title) => {
        let tempEvents = [...eventLabel];
        let newSelectedEvents = [...selectedEvents];
        let sectorsIndex = newSelectedEvents.indexOf(events[getIndex(title, events, "title")].id);
        let labelIndex = tempEvents.indexOf(title);
        tempEvents.splice(labelIndex, 1);
        newSelectedEvents.splice(sectorsIndex, 1);
        setSelectedEvents(newSelectedEvents);
        setEventLabel(tempEvents);

    }

    const removeYear = (title) => {
        let tempYear = [...yearLabel];
        let newSelectedYear = [...selectedYears];
        let sectorsIndex = newSelectedYear.indexOf(generalDataGlobal[0]?.years[getIndex(title, generalDataGlobal[0]?.years, "year")].id);
        let labelIndex = tempYear.indexOf(title);
        tempYear.splice(labelIndex, 1);
        newSelectedYear.splice(sectorsIndex, 1);
        setSelectedYears(newSelectedYear);
        setYearLabel(tempYear);
    }



    const handleModal = (index) => {
        setChosenIndex(index);
    }

    useEffect(() => {
        if (typeof chosenIndex !== "undefined") {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [chosenIndex]);

    useEffect(() => {
        if (lastPage) {
            setNbOfPages([])
            for (let index = 1; index < lastPage + 1; index++) {
                setNbOfPages((nbOfPages) => [...nbOfPages, index]);
            }
        }
        // eslint-disable-next-line
    }, [lastPage])

    useEffect(() => {
        setShowGallery(true);
    }, [gallery]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        document.addEventListener("click", function (e) {
            if (!e.target.closest(".filter-col")) {
                setShowEvents(false);
                setShowYears(false);
            }
        });
    }, []);

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "gallery",
            callback: (res) => {
                setGallerySettings(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setEventsDataApiOptions({
            url: "events",
            callback: (res) => {
                setEvents(res.data);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setShowGallery(false);

        setGalleryDataApiOptions({
            url: "gallery-list?page=" + currentPage,
            params: {
                events: selectedEvents,
                years: selectedYears,
                format: format

            },
            callback: (res) => {
                setGallery(res.data.data);

                setLastPage(res.data.last_page);
                if (currentPage === res.data.last_page) {
                    setDisableNext(true);
                } else {
                    setDisableNext(false);
                }

                if (currentPage === 1) {
                    setDisablePrev(true);
                } else {
                    setDisablePrev(false);
                }

                if (selectedEvents.length || selectedYears.length || format) {
                    setShowClear(true);
                } else {
                    setShowClear(false);
                }



            },
        });
    }, [currentPage, selectedEvents, selectedYears, format]); // eslint-disable-line react-hooks/exhaustive-deps


    const selectEvent = (id, title) => {

        let newSelectedEvents = [...selectedEvents];
        let sectorIndex = newSelectedEvents.indexOf(id);
        if (sectorIndex === -1) {
            newSelectedEvents.push(id);
        } else {
            newSelectedEvents.splice(sectorIndex, 1);
        }

        let newEventLabel = [...eventLabel];
        let sectorIndex1 = newEventLabel.indexOf(title);
        if (sectorIndex1 === -1) {
            newEventLabel.push(title);
        } else {
            newEventLabel.splice(sectorIndex1, 1);
        }

        setSelectedEvents(newSelectedEvents);
        setEventLabel(newEventLabel);
        setCurrentPage(1);
    }

    const selectYear = (id, title) => {
        let newSelectedYear = [...selectedYears];
        let sectorIndex = newSelectedYear.indexOf(id);
        if (sectorIndex === -1) {
            newSelectedYear.push(id);
        } else {
            newSelectedYear.splice(sectorIndex, 1);
        }

        let newYearLabel = [...yearLabel];
        let yearIndex1 = newYearLabel.indexOf(title);
        if (yearIndex1 === -1) {
            newYearLabel.push(title);
        } else {
            newYearLabel.splice(yearIndex1, 1);
        }
        setSelectedYears(newSelectedYear);
        setYearLabel(newYearLabel);
        setCurrentPage(1);

    }

    const clearFilters = () => {
        setSelectedEvents([])
        setEventLabel([])
        setSelectedYears([])
        setYearLabel([])
        setFormat()
    }

    useEffect(() => {
        window.triggerScroll();
    })

    const ThumbnailComponent = ({ el, index }) => {
        return (
            <div className="gallery-image-contaner ratio ratio-1x1">
                {
                    el.video ?
                        <>

                            <div className="zoom-overlay video" onClick={() => handleModal(index)}>
                                <img src="/assets/images/play-white.svg" alt="" />
                            </div>
                            <img className="thumb" src={process.env.REACT_APP_BASE_URL + el.thumbnail} alt="thumbnail" />
                        </>
                        :
                        el.youtube ?
                            <>

                                <div className="zoom-overlay video" onClick={() => handleModal(index)}>
                                    <img src="/assets/images/play-white.svg" alt="" />
                                </div>
                                <img className="thumb" src={process.env.REACT_APP_BASE_URL + el.thumbnail} alt="thumbnail" />
                            </>
                            :
                            <>
                                <div className="zoom-overlay" onClick={() => handleModal(index)}>
                                    <img src="/assets/images/zoom.svg" alt="" />
                                </div>
                                <img src={process.env.REACT_APP_BASE_URL + el.image} alt="gallery-img" />
                            </>
                }
            </div>
        )
    }

    const Popup = ({ galleryItem }) => {
        return (
            <>
                {
                    galleryItem.video ?
                        <>
                            <video className="mw-100 mh-100" controls>
                                <source src={process.env.REACT_APP_BASE_URL + galleryItem.video} />
                            </video>
                        </>
                        :
                        galleryItem.youtube ?
                            <>
                                <div className="youtube-iframe-wrapper h-100 w-100" dangerouslySetInnerHTML={{ __html: galleryItem.youtube }}></div>
                            </>
                            :
                            <>
                                <img src={process.env.REACT_APP_BASE_URL + galleryItem.image} className="mw-100 mh-100" alt="" />
                            </>


                }
            </>
        )
    }

    return (

        <>
            <Api options={generalDataApiOptions} />
            <Api options={eventsDataApiOptions} />
            <Api options={galleryDataApiOptions} />



            {
                gallerySettings ?
                    <Layout activePage="library">
                        <Helmet>
                            <title>{generalDataGlobal[0]?.seo[7].title}</title>
                            <meta name="title" content={generalDataGlobal[0]?.seo[7].title} />
                            <meta name="description" content={generalDataGlobal[0]?.seo[7].description} />

                            <meta property="og:title" content={generalDataGlobal[0]?.seo[7].title} />
                            <meta property="og:description" content={generalDataGlobal[0]?.seo[7].description} />
                            <meta property="og:image" content={generalDataGlobal[0]?.seo[7].image_path} />

                            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[7].title} />
                            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[7].description} />
                            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[7].image_path} />
                        </Helmet>
                        <div className="gallery-page-title" animate="">
                            <h1>{gallerySettings.title}</h1>
                        </div>

                        <section className="gallery-page-section" animate="right">
                            <div className="container">
                                <div className="row survey-filter-row" style={{ marginBottom: (!eventLabel?.length && !yearLabel?.length) ? "50px" : "" }}>
                                    <div className={"col-lg-2 position-relative gal filter-col sectors-col-filter " + ((eventLabel?.length || yearLabel?.length) ? "add-pad" : " ")} onClick={() => { setShowEvents(!showEvents); setShowYears(false) }} >
                                        <div className="sectoral-filter d-flex justify-content-between align-items-center" >
                                            <div className="sectoral-filter-title">
                                                <p>{gallerySettings.event_placeholder}</p>
                                            </div>
                                            <img
                                                src="/assets/images/arrow-down-sign-to-navigate.svg"
                                                alt="arrow down"
                                                style={{ transform: showEvents ? "rotateX(180deg)" : "" }}

                                            />

                                        </div>

                                        {
                                            showEvents ?
                                                <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute">
                                                    {
                                                        events ?
                                                            events.map((event) => (
                                                                // eslint-disable-next-line
                                                                <a key={event.id} href={null} onClick={() => { selectEvent(event.id, event.title); }}>
                                                                    <p  >
                                                                        {event.title}
                                                                    </p>
                                                                </a>

                                                            ))
                                                            : null
                                                    }

                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                    <div className={"col-lg-2 position-relative gal filter-col sectors-col-filter " + ((eventLabel?.length || yearLabel?.length) ? "add-pad" : "")} onClick={() => { setShowEvents(false); setShowYears(!showYears) }} >
                                        <div className="sectoral-filter d-flex justify-content-between align-items-center" >
                                            <div className="sectoral-filter-title">
                                                <p>{gallerySettings.date_placeholder}</p>
                                            </div>
                                            <img
                                                src="/assets/images/arrow-down-sign-to-navigate.svg"
                                                alt="arrow down"
                                                style={{ transform: showYears ? "rotateX(180deg)" : "" }}

                                            />

                                        </div>



                                        {
                                            showYears ?
                                                <div className="sectors-dropdown activee  dropdown d-flex flex-column align-items-start position-absolute" >
                                                    {
                                                        generalDataGlobal[0]?.years?.length ?
                                                            generalDataGlobal[0]?.years?.map((year) => (
                                                                // eslint-disable-next-line
                                                                <a href={null} key={year.id} onClick={() => selectYear(year.id, year.year)}>

                                                                    <p >
                                                                        {year.year}
                                                                    </p>
                                                                </a>

                                                            ))
                                                            : null
                                                    }

                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                    <div className="row d-flex d-lg-none" style={{ marginBottom: (eventLabel?.length) ? "20px" : "" }}>

                                        {
                                            eventLabel?.length ?
                                                eventLabel.map((label, index) => (
                                                    <div key={index} className="col-auto ">
                                                        <div className="sectoral-filter chosen gal mobile d-flex justify-content-between align-items-center" >
                                                            <div className="sectoral-filter-title">
                                                                <p style={{ paddingRight: "10px" }}>{label}</p>
                                                            </div>
                                                            <img
                                                                src="/assets/images/close.svg"
                                                                alt="remove filter  "
                                                                className="remove-filter"
                                                                onClick={() => removeEvent(label)}
                                                            />
                                                        </div>
                                                    </div>

                                                ))
                                                : null
                                        }
                                        {
                                            yearLabel?.length ?
                                                yearLabel?.map((label, index) => (
                                                    <div key={index} className="col-lg-2 col-auto">

                                                        <div className="sectoral-filter chosen mobile gal d-flex justify-content-between align-items-center" >
                                                            <div className="sectoral-filter-title">
                                                                <p style={{ paddingRight: "10px" }}>{label}</p>
                                                            </div>
                                                            <img
                                                                src="/assets/images/close.svg"
                                                                alt="remove filter  "
                                                                className="remove-filter"
                                                                onClick={() => removeYear(label)}
                                                            />
                                                        </div>
                                                    </div>

                                                ))
                                                : null
                                        }

                                    </div>
                                    <div className="col-lg-auto d-flex align-items-center">
                                        <div className="format-container ">
                                            <p>{gallerySettings.format_placeholder}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-auto d-flex align-items-center">
                                        <label className="d-flex align-items-center">
                                            <input type="radio" value="image" name="format" checked={format === "image"} onChange={(e) => { setFormat(e.target.value); setCurrentPage(1) }} /> {gallerySettings.images_placeholder}
                                        </label>
                                    </div>

                                    <div className="col-lg-auto d-flex align-items-center">
                                        <label className="d-flex align-items-center">
                                            <input type="radio" value="video" name="format" checked={format === "video"} onChange={(e) => { setFormat(e.target.value); setCurrentPage(1) }} /> {gallerySettings.videos_placeholder}
                                        </label>
                                    </div>

                                    {
                                        showClear ?

                                            <div className="col-lg-2 d-flex align-items-center">
                                                <p className="clear-all-label gallery" onClick={clearFilters}>{gallerySettings.clear_all}</p>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <div className="row d-none d-lg-flex" style={{ marginBottom: (eventLabel?.length) ? "20px" : "" }}>

                                    {
                                        eventLabel?.length ?
                                            eventLabel.map((label, index) => (
                                                <div key={index} className="col-auto ">
                                                    <div className="sectoral-filter chosen gal mobile d-flex justify-content-between align-items-center" >
                                                        <div className="sectoral-filter-title">
                                                            <p style={{ paddingRight: "10px" }}>{label}</p>
                                                        </div>
                                                        <img
                                                            src="/assets/images/close.svg"
                                                            alt="remove filter  "
                                                            className="remove-filter"
                                                            onClick={() => removeEvent(label)}
                                                        />
                                                    </div>
                                                </div>

                                            ))
                                            : null
                                    }
                                    {
                                        yearLabel?.length ?
                                            yearLabel?.map((label, index) => (
                                                <div key={index} className="col-lg-2 col-auto">

                                                    <div className="sectoral-filter chosen mobile gal d-flex justify-content-between align-items-center" >
                                                        <div className="sectoral-filter-title">
                                                            <p style={{ paddingRight: "10px" }}>{label}</p>
                                                        </div>
                                                        <img
                                                            src="/assets/images/close.svg"
                                                            alt="remove filter  "
                                                            className="remove-filter"
                                                            onClick={() => removeYear(label)}
                                                        />
                                                    </div>
                                                </div>

                                            ))
                                            : null
                                    }

                                </div>

                                {
                                    gallery?.length ?
                                        <>
                                            <div className={"row gallery " + (showGallery ? ' show' : '')} >
                                                {
                                                    gallerySettings.featured ?
                                                        <>
                                                            <div className="col-md-6 featured-col">
                                                                <ThumbnailComponent el={gallerySettings.featured} index={0} />
                                                            </div>
                                                            <div className="col-md-6 ">
                                                                <div className="row">
                                                                    {
                                                                        gallery.map((el, index) => (
                                                                            index < 4 && (
                                                                                <div className="col-6 padding-bott">
                                                                                    <ThumbnailComponent el={el} index={index + 1} />
                                                                                </div>
                                                                            )
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    gallery ?
                                                        gallery.map((el, index) => (
                                                            !!(index >= (gallerySettings.featured ? 4 : 0)) && (
                                                                <div key={index} className={"col-lg-3 col-md-4 col-6 gallery-image-col "}>
                                                                    <ThumbnailComponent el={el} index={index + (gallerySettings.featured ? 1 : 0)} />
                                                                </div>
                                                            )
                                                        ))
                                                        : null
                                                }
                                            </div>
                                        </>
                                        : <div className="no-results container">
                                            <div className="row" style={{ marginBottom: "90px", marginTop: "90px" }}>
                                                <div className="col-12 d-flex justify-content-center no-results">
                                                    <h1>No Results Found!</h1>
                                                </div>
                                            </div>
                                        </div>
                                }


                                {
                                    gallery?.length ?
                                        <div className="container pagination-container">
                                            <div className="row ">
                                                <div className="col-12">
                                                    <div className="pagination-content d-flex justify-content-center">
                                                        <div className={"prev-arrow me-2" + (disablePrev ? " low-opacity" : "")}
                                                            onClick={!disablePrev ? () => { setCurrentPage(currentPage - 1); } : undefined}
                                                        >
                                                            {/* eslint-disable-next-line*/}
                                                            <a href={null}>

                                                                <img src="/assets/images/next-arrow.svg" alt="" />
                                                            </a>

                                                        </div>

                                                        <div className="pages d-flex">
                                                            {
                                                                nbOfPages?.map((page, index) => (
                                                                    <div key={index} className={"page-div d-flex justify-content-center align-items-center me-2 " + (index + 1 === currentPage ? " active-page" : "")}
                                                                        onClick={() => { setCurrentPage(index + 1); }}
                                                                    >
                                                                        {/* eslint-disable-next-line*/}
                                                                        <a href={null}>

                                                                            <p>{page}</p>
                                                                        </a>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                        <div className={"next-arrow " + (disableNext ? " low-opacity" : "")}
                                                            onClick={!disableNext ? () => { setCurrentPage(currentPage + 1); } : undefined}
                                                        >
                                                            {/* eslint-disable-next-line*/}
                                                            <a href={null}>
                                                                <img src="/assets/images/next-arrow.svg" alt="" />
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div> : null
                                }
                            </div>
                        </section>

                        {
                            typeof chosenIndex !== "undefined" ?
                                <div className="images-popup position-fixed h-100 w-100 top-0 start-0" animate="left">
                                    <div className="h-100 w-100 p-5">
                                        <div className="d-flex align-items-center justify-content-center h-100 p-2 p-sm-5">
                                            {
                                                gallerySettings.featured && chosenIndex === 0 ?
                                                    <Popup galleryItem={gallerySettings.featured} />
                                                    :
                                                    <>
                                                        {
                                                            gallery.map((galleryItem, index) => (
                                                                index + (gallerySettings.featured ? 1 : 0) === chosenIndex && (
                                                                    <>
                                                                        <Popup galleryItem={galleryItem} />
                                                                    </>
                                                                )
                                                            ))
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className={"next-arrow-modal position-absolute top-50 end-0 translate-middle-y"}
                                        onClick={((chosenIndex < gallery?.length - 1)) ? () => setChosenIndex(chosenIndex + 1) : undefined}
                                    >
                                        {/* eslint-disable-next-line*/}
                                        <a href={null}>
                                            <img src="/assets/images/next-arrow.svg" alt="" />
                                        </a>
                                    </div>
                                    <div className={"prev-arrow-modal position-absolute top-50 start-0 translate-middle-y"}
                                        onClick={(chosenIndex > 0) ? () => setChosenIndex(chosenIndex - 1) : undefined}
                                    >
                                        {/* eslint-disable-next-line*/}
                                        <a href={null}>
                                            <img src="/assets/images/next-arrow.svg" alt="" />
                                        </a>
                                    </div>
                                    <div className={"cancel-modal position-absolute top-0 end-0"}
                                        onClick={() => { setChosenIndex() }}

                                    >
                                        {/* eslint-disable-next-line*/}
                                        <a href={null}>

                                            <img src="/assets/images/close-modal.svg" alt="" />
                                        </a>

                                    </div>
                                </div>
                                :
                                null

                        }
                    </Layout>

                    :
                    <div className="custom-loader"></div>
            }

        </>
    )
}
export default Gallery;