import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Api from "../components/Api";
import Layout from "../components/Layout";
import SingleSector from "../components/SingleSector";
import GlobalState from "../contexts/GlobalState";

const Sectors = (props) => {
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [sectorsDataApiOptions, setSectorsDataApiOptions] = useState({});
  const { setIsOpen } = useContext(GlobalState);

  const [sectorsData, setSectorsData] = useState();
  const [sectors, setSectors] = useState();

  const { generalDataGlobal } =
    useContext(GlobalState);

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "sectors-settings",
      callback: (res) => {
        setSectorsData(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSectorsDataApiOptions({
      url: "sectors",
      callback: (res) => {
        setSectors(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [sectors])

  useEffect(() => {
    window.triggerScroll();
  })

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={sectorsDataApiOptions} />

      {sectorsData ? (
        <Layout activePage="sectors">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo[2].title}</title>
            <meta name="title" content={generalDataGlobal[0]?.seo[2].title} />
            <meta name="description" content={generalDataGlobal[0]?.seo[2].description} />

            <meta property="og:title" content={generalDataGlobal[0]?.seo[2].title} />
            <meta property="og:description" content={generalDataGlobal[0]?.seo[2].description} />
            <meta property="og:image" content={generalDataGlobal[0]?.seo[2].image_path} />

            <meta property="twitter:title" content={generalDataGlobal[0]?.seo[2].title} />
            <meta property="twitter:description" content={generalDataGlobal[0]?.seo[2].description} />
            <meta property="twitter:image" content={generalDataGlobal[0]?.seo[2].image_path} />
          </Helmet>
          <section className="sectoral-section position-relative" animate="">
            <div className="col-xl-3 blue-sector-div d-none d-xl-block">
              <div className="sectoral-categories position-relative">
                <div className="logo-sector-container position-absolute">
                  <img src="/assets/images/earrel.svg" alt="logo" />
                </div>
                <div className="sector-section-title col-lg-10">
                  <h1> {sectorsData.title} </h1>
                </div>
                <div
                  className="sector-section-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: sectorsData.excerpt,
                  }}
                ></div>
              </div>
            </div>
            <div className="sectors-section">
              <div className="container">
                <div className="row">
                  <div className="col-xl-3 blue-sector-div d-block d-xl-none">
                    <div className="sectoral-categories position-relative">
                      <div className="logo-sector-container position-absolute">
                        <img src="/assets/images/earrel.svg" alt="logo" />
                      </div>
                      <div className="sector-section-title col-6">
                        <h1> {sectorsData.title} </h1>
                      </div>
                      <div
                        className="sector-section-excerpt"
                        dangerouslySetInnerHTML={{
                          __html: sectorsData.excerpt,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-xl-9 offset-xl-3">
                    <div className="row">
                      {sectors
                        ? sectors?.map((sector, index) => (
                          <div className="col-xl-4 col-sm-6 sectors-col">
                            <Link to={sector.slug}>
                              <SingleSector
                                key={index}
                                title={sector.title}
                                excerpt={sector.excerpt}
                                image={sector.image}
                                icon={sector.icon}
                              ></SingleSector>
                            </Link>
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      ) : null}
    </>
  );
};

export default Sectors;
