import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";

const Faq = (props) => {
  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [faq, setFaq] = useState();

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (!e.target.closest(".faq-content-container")) {
        let answers = document.getElementsByClassName("answer");
        for (let index = 0; index < answers.length; index++) {
          answers[index].style.height = 0;
          document.getElementsByClassName("open-faq")[index].innerHTML = "+";
        }
      }
    });
  }, []);

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "faq",
      callback: (res) => {
        setFaq(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAnswer = (e) => {
    let target = document.getElementsByClassName(
      e.currentTarget.parentElement.nextElementSibling.className
    )[0];
    let others = document.querySelectorAll(".faq-content-container");
    for (let index = 0; index < others.length; index++) {
      if (others[index].childNodes[1] !== target) {
        others[index].childNodes[1].style.height = 0;
        others[index].childNodes[0].childNodes[1].innerHTML = "+";
      }
    }
    if (target.clientHeight === 0) {
      target.style.height = target.scrollHeight + "px";

      e.currentTarget.innerHTML = "-";
    } else {
      target.style.height = 0;
      e.currentTarget.innerHTML = "+";
    }
  };

  const handleAnswerDiv = (e) => {
    let target = e.currentTarget.childNodes[0].childNodes[1];

    let others = document.querySelectorAll(".faq-content-container");
    for (let index = 0; index < others.length; index++) {
      if (others[index].childNodes[1] !== target) {
        others[index].childNodes[1].style.height = 0;
        others[index].childNodes[0].childNodes[1].innerHTML = "+";
      }
    }

    if (target.clientHeight === 0) {
      target.style.height = target.scrollHeight + "px";
      target.parentElement.childNodes[0].childNodes[1].innerHTML = "-";
    } else {
      target.style.height = 0;
      target.parentElement.childNodes[0].childNodes[1].innerHTML = "+";
    }
  };

  // const closeAnswer = (e) => {
  //     let target = document.getElementsByClassName(e.currentTarget.previousElementSibling.childNodes[1].className)[0];
  //     setShowAnswer(false)
  // }

  useEffect(() => {
    window.triggerScroll();
  })

  return (
    <>
      <Api options={generalDataApiOptions} />

      {
        faq ?
          <Layout>
            <Helmet>
              <title>{generalDataGlobal[0]?.seo[10].title}</title>
              <meta name="title" content={generalDataGlobal[0]?.seo[10].title} />
              <meta name="description" content={generalDataGlobal[0]?.seo[10].description} />

              <meta property="og:title" content={generalDataGlobal[0]?.seo[10].title} />
              <meta property="og:description" content={generalDataGlobal[0]?.seo[10].description} />
              <meta property="og:image" content={generalDataGlobal[0]?.seo[10].image_path} />

              <meta property="twitter:title" content={generalDataGlobal[0]?.seo[10].title} />
              <meta property="twitter:description" content={generalDataGlobal[0]?.seo[10].description} />
              <meta property="twitter:image" content={generalDataGlobal[0]?.seo[10].image_path} />
            </Helmet>
            <section className="faq-banner" animate="right">
              <div className="faq-image-container position-relative">
                <div className="faq-image">
                  <img
                    src={
                      process.env.REACT_APP_BASE_URL +
                      generalDataGlobal[0]?.faq.image
                    }
                    alt=""
                  />
                </div>
                <div className="faq-banner-content position-absolute col-lg-5 col-md-7 col-sm-9 col-10">
                  <div
                    className="faq-title"
                    dangerouslySetInnerHTML={{
                      __html: generalDataGlobal[0]?.faq.title,
                    }}
                  ></div>

                  <div className="faq-excerpt">
                    <p>{generalDataGlobal[0]?.faq.excerpt}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="faqs-section" animate="left">
              <div className="container faq-container">
                <div className="row faq-row">
                  {faq
                    ? faq?.faq.map((faq, index) => (
                      <>
                        <div
                          key={index}
                          className="col-12 faq-question-answer position-relative shadow"
                          onClick={handleAnswerDiv}
                        >
                          <div className="faq-content-container ">
                            <div className="question d-flex justify-content-between align-items-center">
                              <p>{faq.question}</p>
                              <span
                                className={"open-faq " + index}
                                onClick={handleAnswer}
                              >
                                +
                              </span>
                            </div>
                            <div
                              className={"col-11 answer no-height " + index}
                              dangerouslySetInnerHTML={{
                                __html: faq.answer,
                              }}
                            ></div>
                          </div>
                        </div>
                      </>
                    ))
                    : null}
                </div>
              </div>
            </section>
          </Layout>
          :
          <div className="custom-loader"></div>
      }
    </>
  );
};

export default Faq;
