export default function SubsectorContent({ selectedSubsectorId, sub, subsectorContentHeight, refs, initialIndex }) {
    return (
        <div
            className="overflow-hidden remove-pad"
            style={{ height: selectedSubsectorId === sub.id ? subsectorContentHeight : 0, transition: '0.5s' }}
            ref={el => refs.current[initialIndex] = el}
        >
            <div className="subsector-description justify-content-center">
                <div
                    className="description-subsector"
                    dangerouslySetInnerHTML={{
                        __html: sub.description,
                    }}
                />
            </div>
            <div style={{ paddingBottom: 20 }} />
        </div>
    )
}