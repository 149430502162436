import { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";

const PageNotFound = (props) => {

  const { generalDataGlobal } = useContext(GlobalState);

  return (
    <>
      <Helmet>
        <title>{"PAGE NOT FOUND"}</title>
        <meta name="title" content={"PAGE NOT FOUND"} />
        <meta name="description" content={"the page you are looking for could not be found at the moment"} />

        <meta property="og:title" content={"PAGE NOT FOUND"} />
        <meta property="og:description" content={"the page you are looking for could not be found at the moment"} />

        <meta property="twitter:title" content={"PAGE NOT FOUND"} />
        <meta property="twitter:description" content={"the page you are looking for could not be found at the moment"} />
      </Helmet>
      <div className="main-404 position-relative">
        <div className="lamps-container container">
          <div className="row lamps-row">
            <div className="col-4 first-lamp justify-content-center">
              <img src="/assets/images/lamp.png" alt="" />
              <img className="position-absolute light" src="/assets/images/light.png" alt="" />


            </div>
            <div className="col-4  justify-content-center middle-lamp">
              <img src="/assets/images/lamp.png" alt="" />
              <img className="position-absolute light" src="/assets/images/light.png" alt="" />
            </div>
            <div className="col-4 d-flex justify-content-center">
              <img src="/assets/images/lamp.png" alt="" />
              <img className="position-absolute light" src="/assets/images/light.png" alt="" />


            </div>
          </div>

        </div>
        <Link to="/">

          <img className="logo-404" src="/assets/images/earrel-logo.svg" alt="" />
        </Link>
        <div className="content-container-404 position-relative">
          <div className="content-404 d-flex flex-column align-items-center">
            <div className="title-404">
              <h1> {generalDataGlobal[0]?.not_found.title} </h1>
            </div>
            <div className="not-found d-flex flex-column align-items-center mb-3">
              <div className="page-not-found">
                <p>{generalDataGlobal[0]?.not_found.not_found}</p>
              </div>
              <div className="request-not-found">
                <p>{generalDataGlobal[0]?.not_found.subtitle}</p>
              </div>
            </div>

            <Link to="/">

              <button className="accept-button">{generalDataGlobal[0]?.not_found.button_text}</button>
            </Link>
          </div>
        </div>

      </div>
    </>
  );
};

export default PageNotFound;
