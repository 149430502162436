import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/Api";
import Layout from "../components/Layout";

const TrackerMethodology = () => {


    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

    const [trackerData, setTrackerData] = useState();
    const [levels, setLevels] = useState();
    const [searchList, setSearchList] = useState();
    const [stakeholders, setStakeHolders] = useState();
    const [openVid, setOpenVid] = useState(false);


    const handleVideo = () => {
        setOpenVid(true);
    }

    useEffect(() => {
        setGeneralDataApiOptions({
            url: "tracker-method-settings",
            callback: (res) => {
                setTrackerData(res.data.tracker_method_settings);
                setLevels(res.data.levels);
                setSearchList(res.data.searchByList);
                setStakeHolders(res.data.stakeholders);
            },
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (trackerData) {
            document.addEventListener('keydown', function (e) {
                e = e || window.event;
                // eslint-disable-next-line
                if (e.keyCode == '27') {
                    // right arrow
                    setOpenVid(false)
                }
            });
        }
        // eslint-disable-next-line
    }, [trackerData])


    useEffect(() => {
        window.triggerScroll();
    })

    return (

        <>
            <Api options={generalDataApiOptions} />


            {
                trackerData ?
                    <Layout>
                        <Helmet>
                            <title>{"Tracker Methodology"}</title>
                            <meta name="title" content={"Tracker Methodology"} />
                            <meta name="description" content={"Tracker Methodology"} />

                            <meta property="og:title" content={"Tracker Methodology"} />
                            <meta property="og:description" content={"Tracker Methodology"} />

                            <meta property="twitter:title" content={"Tracker Methodology"} />
                            <meta property="twitter:description" content={"Tracker Methodology"} />
                        </Helmet>
                        <section className="single-sector-section position-relative" animate="left">
                            <div className="container">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-3 offset-lg-1 single-sector-image-col position-relative">
                                        <div className="single-sector-image ratio ratio-single-sector">
                                            <img
                                                src={process.env.REACT_APP_BASE_URL + trackerData.image}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="single-sector-image-title tracker d-flex flex-column justify-content-center">

                                            <div className="single-sector-title tracker">
                                                <h1>{trackerData.title}</h1>
                                            </div>
                                            <div className="tracker-why">
                                                <p>
                                                    {trackerData.why_tracker}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="single-sector-description tracker"
                                            dangerouslySetInnerHTML={{
                                                __html: trackerData.description,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className="single-sector-left-section position-absolute col-lg-3 d-none d-lg-block"></div>

                            <div className="single-sector-right-section position-absolute col-1 d-none d-lg-block"></div>

                            <div className="single-sector-top-section position-absolute d-block d-lg-none"></div>
                        </section>

                        <section className="tracker-levels" animate="right">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-8 col-lg-10 col-12">
                                        <div className="tracker-level-title">
                                            <h1>{trackerData.section2_title}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="row levels-row">
                                    {
                                        levels?.length ?
                                            levels?.map((level, index) => (

                                                <div key={level.id} className="col-md-4 level-col d-flex justify-content-center">
                                                    <div className="level-content d-flex flex-column w-100 ">
                                                        <div className="level-title d-flex align-items-center">
                                                            <span className="level-number">{"0" + (index + 1)}</span>
                                                            <h1>{level.title}</h1>
                                                        </div>
                                                        <hr className="level-hr" />
                                                        <div className="level-excerpt">
                                                            <p>{level.excerpt}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))

                                            :
                                            null
                                    }
                                </div>
                                <div className="row video-row justify-content-center">
                                    <div className="col-sm-10">
                                        <div className="thumbnail-tracker ratio ratio-16x9" onClick={handleVideo}>
                                            <div className="half-overlay position-absolute"></div>
                                            <div className="play-btn">
                                                <img src="/assets/images/red-play.svg" alt="" />
                                            </div>
                                            <img src={process.env.REACT_APP_BASE_URL + trackerData.thumbnail} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row search-by justify-content-center">
                                    <div className="search-by-title">
                                        <h1>{trackerData.section3_title}</h1>
                                    </div>
                                    {
                                        searchList?.length ?
                                            searchList.map(el => (
                                                <div className="col-lg-3 col-md-4 col-sm-6 search-col">
                                                    <div className="search-list-content d-flex flex-column align-items-center shadow h-100">
                                                        <div className="icon-search shadow">
                                                            <img src={process.env.REACT_APP_BASE_URL + el.icon} alt="" />
                                                        </div>
                                                        <div className="search-title">
                                                            <h1>{el.title}</h1>
                                                        </div>
                                                        <div className="search-excerpt">
                                                            <p>{el.excerpt}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                                <div className="row limitations-row">
                                    <div className="limitations-title">
                                        <h1>{trackerData.section3_subtitle1}</h1>
                                    </div>
                                    <div className="limitations-subtitle">
                                        <p>{trackerData.section3_subtitle2}</p>
                                    </div>
                                </div>
                                <div className="row stakeholders-row justify-content-center">
                                    <div className="search-by-title">
                                        <h1>{trackerData.section4_title}</h1>
                                    </div>


                                    {
                                        stakeholders?.length ?
                                            stakeholders.map(el => (
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 stake-col">
                                                    <div className="search-list-content d-flex flex-column align-items-center shadow h-100">
                                                        <div className="icon-stake shadow">
                                                            <img src={process.env.REACT_APP_BASE_URL + el.icon} alt="" />
                                                        </div>
                                                        <div className="stake-title">
                                                            <h1>{el.title}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            null
                                    }
                                </div>
                            </div>

                        </section>
                        {
                            openVid ?
                                <div className="images-popup position-fixed h-100 w-100 top-0 start-0">
                                    <div className="h-100 w-100 p-5">
                                        <div className="d-flex align-items-center justify-content-center h-100 p-2 p-sm-5">
                                            {
                                                trackerData.video ?
                                                    <>
                                                        <video className="mw-100 mh-100" controls>
                                                            <source src={process.env.REACT_APP_BASE_URL + trackerData.video} />
                                                        </video>
                                                    </>
                                                    :
                                                    trackerData.youtube ?
                                                        <>
                                                            <div className="youtube-iframe-wrapper h-100 w-100" dangerouslySetInnerHTML={{ __html: trackerData.youtube }}></div>
                                                        </>
                                                        :
                                                        null


                                            }
                                        </div>
                                    </div>
                                    <div className={"cancel-modal position-absolute top-0 end-0"}
                                        onClick={() => { setOpenVid(false) }}

                                    >
                                        {/* eslint-disable-next-line*/}
                                        <a href={null}>

                                            <img src="/assets/images/close-modal.svg" alt="" />
                                        </a>

                                    </div>
                                </div>
                                :
                                null

                        }
                    </Layout >
                    : <div className="custom-loader"></div>
            }
        </>
    )
}

export default TrackerMethodology;